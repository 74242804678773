import en from './locales/en.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import ar from './locales/ar.json';
import de_DE from './locales/de-de.json'
import nl_NL from './locales/nl-nl.json';
import fr_CA from './locales/fr-ca.json';
import fr_FR from './locales/fr-fr.json';
import en_CA from './locales/en-ca.json';
import en_US from './locales/en-us.json';
import en_GB from './locales/en-gb.json';

const messages = {
	en,
	fr,
	es,
	ar,
	'fr-ca': fr_CA,
	'fr-fr': fr_FR,
	'en-ca': en_CA,
	'en-us': en_US,
	'en-gb': en_GB,
	'nl-nl': nl_NL,
	'de-de': de_DE
};
const availableLanguages = Object.keys(messages);

export default messages;

export {
	availableLanguages,
}
