<template>
	<div>
		<v-dialog
			v-model="modalOpen"
			max-width="800"
			scrollable
			content-class="cross-sell_modal"
			@click:outside="goToCart"
			>
				<v-card>
					<v-card-text>
						<div class="d-flex justify-end mt-2">
							<v-btn
								class="btn-close"
								color="#383838"
								icon
								@click="goToCart">
									<v-icon>mdi-close-circle-outline</v-icon>
							</v-btn>
						</div>
						<div class="cross-sell_header text-left mb-8">
							<h3 class="cross-sell_title">{{ $t('product.crossSells.title') }}</h3>
							<div class="text-subtitle-1 mt-4">{{ $t('product.crossSells.subTitle') }}</div>
						</div>
						<template v-if="interactiveMap">
							<div class="px-0 px-md-4 text-center">
								<h1 class="display-1" v-text="$t('interactiveMap.modalTitle')"></h1>
								<v-container class="cross-sell-container">
									<InteractiveMapButton :interactive-map="interactiveMap" :on-click="() => {_visible = false}" />
								</v-container>
							</div>
							<v-divider class="mb-5"></v-divider>
						</template>
						<div class="cross-sell_product-list d-flex flex-column">
							<ProductCard
								v-for="(product, index) in crossSellProducts"
								:key="index"
								:product="product"
								:products-selected="productsSelected"
								is-hard-cross-sell
								@quantityChanged="updateProductQuantity"
								@quantityModifierChanged="updateModifierQuantity"
								/>
						</div>
					</v-card-text>

					<v-card-actions>
							<v-btn
								class="cancel-button"
								outlined @click="goToCart"
								:block="$vuetify.breakpoint.xs">
								{{ $t('product.crossSells.backButton') }}
							</v-btn>
							<v-btn
								outlined
								class="add-to-cart-button ml-0"
								:disabled="!canAddToCart"
								:block="$vuetify.breakpoint.xs"
								@click="goNext">
								{{ nextLabel }}
							</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<ProductPackageSelector
			v-if="canAddToCart"
			ref="ProductPackageSelector"
			is-cross-sell
			:is-in-cart="isInCart"
			:productsSelected="productsSelected"
			@addToCartSuccess="handleAddToCart"/>
	</div>
</template>

<script>
	import ProductCard from '@/components/ProductV2/ProductCard';
	import ProductV2Mixin from '@/mixins/ProductV2Mixin';
	import ProductPackageSelector from '@/components/ProductV2/ProductPackageSelector';
	import InteractiveMapButton from "@/components/InteractiveMap/InteractiveMapButton";

	export default {
		name: "CrossSellsModal",
		mixins: [ProductV2Mixin],
		components: {
			ProductCard,
			ProductPackageSelector,
			InteractiveMapButton
		},
		props: {
			isOpen: {
				type: Boolean,
				required: false,
			},
			crossSellProducts: {
				type: Array,
				default: () => ([]),
			},
			isInCart: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		data() {
			return {
				modalOpen: this.isOpen,
				productsSelected: new Map(),
				products: [],
				isCrossSell: true //Used in the ProductV2Mixin
			};
		},
		watch: {
			isOpen(newVal) {
				this.modalOpen = newVal;
			},
		},
		computed: {
			canAddToCart() {
				return this.productsSelected.size > 0
			},
			nextLabel() {
				if (this.canAddToCart && this.hasCalendar) {
					return this.$t('tag.chooseVisitingDate');
				}
				return this.$t('btn.addToCart');
			},
			interactiveMap() {
				return this.$root.websiteConfig.data.interactive_map;
			}
		},
		methods: {
			handleAddToCart() {
				this.productsSelected = new Map();
			},
			closeModal() {
				this.modalOpen = false;
				this.$emit("close");
			},
			goToCart() {
				this.isInCart ? this.$router.push({ name: 'cart_step', params: { step: 'identification' } }) : this.$router.push({ name: "cart" });
			},
			goNext() {
				this.hasCalendar
					? this.$refs.ProductPackageSelector.openModal()
					: this.addToCart();
				this.closeModal();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.btn-close {
		margin-right: -24px
	}
	h3 {
		color: $blackish;
	}
	.text-subtitle-1 {
		color: $grey-dark;
	}
	@media screen and (max-width: 600px) {
		.btn-close {
			margin-right: 8px
		}
		h3 {
			font-size: 21px;
			line-height: 28px;
		}
		.text-subtitle-1 {
			font-size: 14px;
			line-height: 21px
		}
	}
	.add-to-cart-button {
		border: 1px solid $blackish;
		background-color: $blackish;
		color: white;
	}
	.add-to-cart-button.v-btn--disabled {
		border: 1px solid $grey-light;
		background-color: $grey-light;
	}
	.cross-sell{
		&_modal{
			> .v-card {
				background-color: $grey-lighter;
				> .v-card__actions{
					padding: 32px;
				}
				> .v-card__actions{
					display: flex;
					justify-content: flex-end;
					gap: 16px
				}
				> .v-card__text {
					padding: 0 32px;
				}
				@media screen and (max-width: 600px) {
					> .v-card__actions{
						display: flex;
						flex-direction: column;
						gap: 16px;
						padding: 16px;
					}
					> .v-card__text {
						padding: 0;
					}
				}
			}
		}
		&_header{
			width: 100%
		}
		@media screen and (max-width: 600px) {
			&_header{
				padding: 0 32px;
			}
		}
		&_product-list {
			gap: 20px
		}
	}
</style>

<style lang="scss">
.v-dialog.v-dialog--active.cross-sell_modal {
	border-radius: 16px;
}
@media screen and (max-width: 600px) {
	.v-dialog.v-dialog--active.cross-sell_modal {
		border-radius: 0;
		.v-card {
			border-radius: 0;
		}
	}
}
</style>
