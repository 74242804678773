<template>
	<Page color="neutralLight" no-sidebar>
		<v-container style="max-width: 35rem" class="py-12">

			<h1 class="text-center display-1" v-text="registerTitle"></h1>

			<p class="mt-12 font-weight-bold">{{ $t('register.advantages.title') }}</p>
			<ul class="mb-12">
				<li v-text="$t('register.advantages.line1')"></li>
				<li v-text="$t('register.advantages.line2')"></li>
			</ul>

			<v-card>
				<v-card-title v-text="$t('register.main')"></v-card-title>
				<v-card-text>
					<CreateAccountForm @registerAsGuest="handleRegisterAsGuest" @completed="handleCompleted" />
				</v-card-text>
			</v-card>

			<v-card class="mt-8">
				<v-card-title v-text="$t('register.alreadyHaveAccount')"></v-card-title>
				<v-card-text>
					<v-btn color="button" block :to="handleConnect">
						<span v-text="$t('btn.connect')"></span>
					</v-btn>
				</v-card-text>
			</v-card>
		</v-container>
	</Page>
</template>

<script>
import Page from '@/components/Page';
import CreateAccountForm from '@/components/CreateAccountForm';

export default {
	name: 'Register',

	components: { Page, CreateAccountForm },
	metaInfo() {
		return {
			title: this.$i18n.t('register.title'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('register.title') },
				{ property: 'og:type', content: 'article' },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	data() {
		return {
			registerAsGuest: false,
		}
	},

	computed: {
		handleConnect() {
			if(this.$route.params?.resId) {
				return {name : 'reservation', params : {lang: this.$route.params?.lang,	resId : this.$route.params?.resId}};
			}

			return { name : 'login'};
		},
		registerTitle() {
			if (this.registerAsGuest) {
				return this.$i18n.t('register.guest.title');
			}
			return this.$i18n.t('register.title');
		}
	},

	methods: {
		handleCompleted(path) {
			if(path) {
				this.$router.push(path)
			}
		},
		handleRegisterAsGuest() {
			this.registerAsGuest = true;
		}
	},

}
</script>
