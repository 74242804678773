<template>
	<v-form class="d-flex flex-column gap-4" :disabled="loading" v-bind="$attrs" ref="form" data-test-selector="login_form" v-model="formIsValid"
			@submit.prevent="handleSubmit" lazy-validation>

		<div v-if="showContinueAsGuestTab" class="tabs d-flex mb-10">
			<div
				class="tab w-50 left"
				:class="{ active: activeTab === 'guest', disabled: cartHasAccountRequiredItems }"
				@click="setActiveTab('guest')"
			>
				{{ $t('loginForm.tab.continue_as_guest') }}
			</div>
			<div
				class="tab w-50 right"
				:class="{ active: activeTab === 'signin' }"
				@click="setActiveTab('signin')"
			>
				{{ $t('loginForm.tab.sign_in') }}
			</div>
		</div>

		<div v-if="showCartHasAccountRequiredMessage" class="message-cart-account-required text--secondary pa-2 mb-8">
			<v-icon class="px-1 py-2 mr-2 float-left">mdi-exclamation</v-icon>
			{{ $t('loginForm.cartHasAccountRequiredProducts') }}
		</div>

		<template>
			<v-text-field
				key="email-guest"
				v-if="isGuestTabActive"
				outlined
				class="text-field"
				data-testid="email"
				v-model="data.guestEmail"
				ref="email"
				:rules="[rules.required, rules.email]"
				:label="$t('input.email')"
				autocomplete="email"
				:disabled="loading"
				:error-messages="guestEmailErrors"
				background-color="white"
				@input="handleInputEmailGuest"
			>
				<template v-slot:append v-if="activeTab === 'guest'">
					<v-icon v-if="!formIsValid" color="#EB5757" dark>mdi-alert-circle</v-icon>
					<v-tooltip v-else right>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								<v-icon color="#009AA6">mdi-information-outline</v-icon>
							</span>
						</template>
						<span>{{ guestTooltip }}</span>
					</v-tooltip>
				</template>
			</v-text-field>
			<v-text-field
				v-else
				key="email"
				outlined
				class="text-field"
				data-testid="email"
				v-model="data.email"
				ref="email"
				:rules="[rules.required, rules.email]"
				:label="$t('input.email')"
				autocomplete="email"
				:disabled="loading"
				:error-messages="formErrors.email || formErrors.password"
				background-color="white"
				@input="formErrors = {}"
			>
			</v-text-field>
			<v-text-field
				key="firstname-guest"
				class="text-field"
				v-if="isGuestTabActive"
				v-model="data.firstname"
				:label="$t('input.first_name')"
				:disabled="loading"
				:error-messages="formErrors.firstname"
				:rules="[rules.required]"
				outlined
				background-color="white"
				@input="formErrors = {}"
			></v-text-field>

			<v-text-field
				key="lastname-guest"
				class="text-field"
				v-if="isGuestTabActive"
				v-model="data.lastname"
				:label="$t('input.last_name')"
				:disabled="loading"
				:error-messages="formErrors.lastname"
				:rules="[rules.required]"
				outlined
				background-color="white"
				@input="formErrors = {}"
			></v-text-field>
		</template>
		<v-text-field
			outlined
			class="text-field"
			v-if="!isGuestTabActive"
			data-testid="password"
			v-model="data.password"
			:label="$t('input.password')"
			:disabled="loading"
			:error-messages="formErrors.password"
			:rules="[rules.required, rules.min]"
			:type="showPassword ? 'text' : 'password'"
			:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
			autocomplete="password"
			background-color="white"
			@click:append="showPassword = !showPassword"
			@input="formErrors = {}"
		>
		</v-text-field>

		<div class="text-left d-flex helper__section">
			<div class="mb-0">
				<v-checkbox v-if="isGuestTabActive && !isGuestExistingEmail" :label="optInMessage" v-model="data.optIn" name="promotional_email" class="w-50 mt-0 mb-0 font-weight-bolder checkbox" hide-details></v-checkbox>
				<v-checkbox v-else-if="!isGuestTabActive" :label="$t('btn.rememberMe')" v-model="rememberMe" name="remember_me" class="w-50 mt-0 mb-0 checkbox" hide-details/>
			</div>
			<a
				v-if="!isGuestTabActive"
				data-testid="forgotPasswordBtn"
				@click.prevent="handleForgotPasswordClick"
				data-test-selector="login_form_forgot_link"
				class="w-50"
			>
				<span v-text="$t('loginForm.forgotPassword')" class="whitespace-nowrap"></span>
			</a>
		</div>

		<template>
			<v-btn data-testid="signInBtn" type="submit" color="primary" class="mt-8 signIn__btn" data-test-selector="login_form_sign_int" :disabled="disableContinue" :loading="loading" block>
				<span v-text="$t(isGuestTabActive ? 'btn.continue' : 'btn.signIn')"></span>
			</v-btn>
		</template>
	</v-form>
</template>

<script>
import {EComService, EventBus, Rules} from '@connectngo/sdk'
import ConfigFieldsMixin from "@/mixins/ConfigFieldsMixin";

export default {
	name: 'LoginForm',

	mixins: [ConfigFieldsMixin],

	props: {
		showContinueAsGuestTab: {
			type: Boolean,
			default: () => false,
		}
	},
	methods: {
		getInitialActiveTab() {
			let activeTab = 'signin';
			if (!this.cartHasAccountRequiredItems) {
				activeTab = (this.getBooleanField('show_guest_checkout_by_default') || this.$root.user.data.is_guest)
					? 'guest'
					: 'signin';
			}
			return activeTab;
		},
		setActiveTab(tab) {
			if ('guest' === tab && this.cartHasAccountRequiredItems) {
				this.showCartHasAccountRequiredMessage = true;
				return;
			}
			this.activeTab = tab;
			// Reset errors when switching tabs.
			this.formErrors = {};
		},
		handleForgotPasswordClick () {
			this.$emit('forgotPassword')
		},

		handleInputEmailGuest() {
			// Reset errors when changing email. Only check existing email on Continue.
			this.isGuestExistingEmail = false;
			this.formErrors.email = '';
		},

		handleContinueAsGuest() {
			// Handle API call and continue to Payment.
			if (this.$gtm) {
				dataLayer.push({
					event: "guestcheckout_proceed_btn",
					tenant_id: this.$root.websiteConfig.data.tenant.id,
				});
				if (this.data.optIn) {
					dataLayer.push({
						event: "guestcheckout_optin_btn",
						tenant_id: this.$root.websiteConfig.data.tenant.id,
					});
				}
			}
			this.loading = true;
			new EComService().registerAsGuest(
				this.data.guestEmail,
				this.$i18n.locale,
				this.data.optIn,
				this.data.firstname,
				this.data.lastname
			).then(response => {
				this.logged(response);
			}).catch(error => {
				this.loading = false;
				if (error.response.status === 422 &&
					error.message &&
					error.errors?.email?.length > 0
				) {
					this.isGuestExistingEmail = true;
					this.formErrors.email = error.errors.email[0];
				} else {
					this.errors(error);
				}
			})
		},

		handleSubmit () {
			if (this.isGuestTabActive) {
				this.handleContinueAsGuest();
				return;
			}

			this.formErrors = {}

			if (this.$refs.form.validate()) {
				this.loading = true

				new EComService().login(this.data.email, this.data.password, this.$root.tenantId)
					.then(response => {
						this.logged(response);
					})
					.catch(error => {
						if (error.response.status === 406) {
							new EComService().loginB2B(this.data.email, this.data.password, this.$root.tenantId)
							.then(response => {
								this.logged(response, true);
							}).catch(error => {
								this.errors(error);
							})
						} else {
							this.errors(error);
						}
					})
			} else {
				this.$refs.email.focus()
			}
		},
		errors(error) {
			this.loading = false
			this.formErrors.email = error.message
			this.formErrors.password = error.message
		},
		logged(response, b2b = false) {
			let data;
			if(this.$route.params.resId) {
				data = {name : 'reservation', params : { lang: this.$route.params?.lang,	resId : this.$route.params?.resId }};
			}

			Object.assign(this.$root.user.data, response.data);

			this.$emit('logged', data);
			EventBus.publish("LOGGED_IN");
			this.loading = false;

			/* Google Tag Manager */
			if (this.$gtm && !b2b) {
				dataLayer.push({
					event: 'login',
					method: 'ConnectnGo',
					tenant_id: this.$root.websiteConfig.data.tenant.id,
				});
			}

			const regex = /\/[a-z]{2}\/login/;

			if(regex.test(this.$route.path)){
				this.$router.push('/');
			}

			if(b2b) {
				EventBus.publish("B2B_LOGGIN_CHANGE");
				if (this.$gtm) {
					dataLayer.push({
						event: "corporate_account_login",
						tenant_id: this.$root.websiteConfig.data.tenant.id,
						salesGroupeId: this.$root.user.data.salesgroup_id
					});
				}
			} else {
				//If we are in B2b we will create a new cart
				this.patchCartAccountId();
			}
		},
		patchCartAccountId() {
			if (!this.$root.user.data.id) {
				return;
			}
			this.loading = true;
			new EComService()
				.patchCart({ account_id: this.$root.user.data.id })
				.then((data) => {
					this.$root.cart = data;
					if(this.isGuestTabActive) {
						EventBus.publish("GUEST_LOGGED_IN");
					}
				})
				.catch((e) => {
					if (new EComService().isMissingRequiredFields(e)) {
						EventBus.publish('MISSING_ACCOUNT_FIELDS', e.fields);
					} else {
						this.$handleError(this, e);
					}
				})
				.finally(() => (this.loading = false));
		},
	},

	computed: {
		guestEmailErrors() {
			if (this.activeTab === 'guest' && this.isGuestExistingEmail) {
				// If the error is the existing email, then show the error returned by the API
				return this.formErrors.email;
			} else {
				// Else return the default error
				return undefined;
			}
		},
		isGuestTabActive() {
			return this.showContinueAsGuestTab && this.activeTab === 'guest';
		},
		disableContinue() {
			if (this.loading || !this.formIsValid) {
				return true;
			}
			if (this.isGuestTabActive) {
				return this.cartHasAccountRequiredItems
					|| !this.data.guestEmail || this.data.guestEmail?.trim() === ''
					|| !this.data.firstname || this.data.firstname?.trim() === ''
					|| !this.data.lastname || this.data.lastname?.trim() === '';
			}

			return !this.data.email || this.data.email?.trim() === ''
				|| !this.data.password || this.data.password?.trim() === ''
		},
		cartHasAccountRequiredItems () {
			return this.$root.cart.data.items.some(item => {
				return item.data.product.data.account_required === 1
					|| item.data.comboItems.some(combo => combo.product.account_required === 1)
			})
		},
		optInMessage() {
			const customText = this.getTranslatedField('opt-in-message', this.$i18n.locale);
			return customText ? customText.replace( /(<([^>]+)>)/ig, '') : this.$i18n.t('btn.guest_promotional_email');
		},
		guestTooltip() {
			const customText = this.getTranslatedField('information-text', this.$i18n.locale);
			return customText ? customText.replace( /(<([^>]+)>)/ig, '') : this.$i18n.t('loginForm.guest.tooltip');
		}
	},

	data () {
		return {
			loading: false,
			formIsValid: false,
			formErrors: {},
			activeTab: 'signin',
			isGuestExistingEmail: false,
			showCartHasAccountRequiredMessage: false,
			data: {
				guestEmail: this.$root.user.data.email || '',
				firstname: this.$root.user.data.firstname || '',
				lastname: this.$root.user.data.lastname || '',
				email: '',
				password: '',
				optIn: this.$root.user.data.promotion || false,
			},
			rememberMe: false,
			showPassword: false,
			rules: {
				required: value => Rules.required(value) || this.$t('rules.required'),
				email: value => Rules.email(value) || this.$t('rules.email'),
				min: value => Rules.min(8, value) || this.$t('rules.min', { amount: 8 }),
			},
		}
	},

	mounted() {
		this.setActiveTab(this.getInitialActiveTab());
	}
}
</script>

<style scoped lang="scss">

.tabs {
	display: flex;
	width: 100%;
	height: 56px;
}

.tab {
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	cursor: pointer;
	background-color: #fff;
	font-weight: 500;
	font-size: 16px;
	border: 1px solid #BDBDBD;
	line-height: 21px;
	text-align: left;

	&.left {
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
	}

	&.right {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	&.disabled {
		cursor: not-allowed;
		color: rgba(0, 0, 0, 0.26);
		background-color: rgba(0, 0, 0, 0.1);
	}
}

.message-cart-account-required {
	background-color: #f8ddcb;
	border: 1px solid #e37222;
	border-radius: 5px;

	.v-icon {
		color: #E37222;
		background-color: #ffffff;
		border-radius: 8px;
	}
}

.tab.active {
	background-color: #f8ddcb;
	border: 1px solid #e37222;
}

.helper__section {
	align-items: center;
	justify-content: space-between;
	width: 100%;
	display: flex;

	.v-checkbox {
		width: 50%;
	}

	a {
		width: 50%;
		font-size: 16px;
		text-decoration: underline;
		text-align: right;
	}
}

.signIn__btn {
	border-radius: 11px;
	color: #fff;
	font-size: 22px;
	font-weight: 700;
	text-transform: uppercase;
	height: 60px !important;
}

::v-deep .v-text-field label {
	font-size: 16px;
	color: #908D8D;
}

::v-deep .text-field fieldset {
	border: solid 2.75px #009AA6 !important;
	padding: 11.02px, 11.02px, 11.02px, 16px;
}

::v-deep .error--text fieldset {
	border: solid 2.75px #EB5757 !important;
	padding: 11.02px, 11.02px, 11.02px, 16px;
}

::v-deep .text-field {
	.v-input__append-outer {
		position: absolute;
		bottom: 10px;
		font-size: 12px;
		font-weight: 500;
		line-height: 14px;
		text-align: left;
		margin-left: 0px;
	}
}

.checkbox label {
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	text-align: left;
}


</style>
