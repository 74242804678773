<template>
	<v-dialog v-model="modalOpen" max-width="600" scrollable @click:outside="closeModal">
		<v-card class="pa-2 rounded-lg" :max-height="cardMaxHeight">
			<v-btn
				class="close-btn sm-image-close-btn"
				icon
				@click="closeModal"
				absolute
				right>
				<v-icon class="">mdi-close-circle-outline</v-icon>
			</v-btn>
			<v-card-text class="pa-0 mt-3">
				<ProductCard
					:product="product"
					:productsSelected="productsSelected"
					:is-in-modal="true"
					@quantityChanged="updateQuantity"
					@quantityModifierChanged="quantityModifierChanged"
				/>
			</v-card-text>
			<div :class="['d-flex justify-end buttons-container mb-5 mt-3', isMobile ? 'pl-4 pr-4' : '']" >
				<v-btn class="cancel-button" outlined @click="closeModal">
					{{ $t('btn.nextTime') }}
				</v-btn>
				<v-btn
					v-if="productIsTimeBased"
					class="add-to-cart-button"
					outlined
					:disabled="!productHasQuantity"
					@click="goNext">
					{{ $t('tag.chooseVisitingDate') }}
				</v-btn>
				<v-btn
					v-else
					class="add-to-cart-button"
					outlined
					:disabled="!productHasQuantity"
					@click="addToCart">
					{{ $t('btn.addToCart') }}
				</v-btn>
			</div>
		</v-card>
	</v-dialog>
</template>


<script>
	import BackgroundImage from '@/assets/images/background.svg';
	import ProductCard from "@/components/ProductV2/ProductCard";
	import WindowWidthMixin from '@/mixins/WindowWidthMixin';

	export default {
		name: "ProductDetailModal",
		mixins: [WindowWidthMixin],
		components: {ProductCard},
		props: {
			product: {
				type: Object,
				required: true
			},
			productsSelected: {
				type: Map,
				required: true
			},
			isOpen: {
				type: Boolean,
				required: true
			},
			withTimeBasedSelection: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				modalOpen: this.isOpen,
			};
		},
		watch: {
			isOpen(newVal) {
				this.modalOpen = newVal;
				if(this.modalOpen == true) {
					this.triggerGAEvent();
				}
			}
		},
		computed: {
			productImg() {
				return this.product.data.image || BackgroundImage;
			},
			productHasQuantity() {
				return this.productsSelected.get(this.product.data.id)?.quantity > 0;
			},
			productIsTimeBased() {
				return this.product.data.event_group_id || this.withTimeBasedSelection ||
					// Check for time based combo
					(this.product.data.combo_products?.length > 0 && this.product.data.combo_products.some(combo => combo.event_group_id))
			},
			cardMaxHeight() {
				return window.innerHeight - 60
			}
		},
		methods: {
			updateQuantity(product, quantity) {
				this.$emit('quantityChanged', product, quantity);
			},
			closeModal() {
				this.modalOpen = false;
				//assign hash to url for marketing purposes
				history.replaceState(null, null, window.location.pathname);

				this.$emit('close');
			},
			goNext() {
				this.closeModal()
				this.$emit('goNext');
			},
			addToCart() {
				this.$emit('addToCart')
				this.closeModal()
			},
			quantityModifierChanged(product, modifier, quantity) {
				this.$emit("quantityModifierChanged", product, modifier, quantity);
			},
			triggerGAEvent() {
				if (this.$gtm) {
					dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
					dataLayer.push({
						event: "view_item",
						tenant_id: this.$root.websiteConfig.data.tenant.id,
						ecommerce: {
							items: [
								{
									item_id: this.product.data.id,
									item_name: this.$options.filters.translatable(
										this.product.data.name,
										this.product.data.name_i18n,
										this.$i18n.locale
									),
									currency: this.$root.websiteConfig.data.tenant
										.currency.code,
									index: 1,
									price: this.product.data.price,
									quantity: 1,
								},
							],
						},
					});
				}
			},
		}
	};
</script>

<style lang="scss" scoped>
	.add-to-cart-button {
		background-color: $blackish;
		color: white;

		&.v-btn--disabled {
			background-color: $grey-light;
		}
	}
	.cancel-button {
		margin-right: 15px;
	}
	.close-btn {
		z-index: 1;
		color: $blackish !important;
	}
	.buttons-container {
		padding-right: 20px;
	}
	@media (max-width: 600px) {
		.buttons-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0 25px;
		}
		.cancel-button {
			margin-right: 0px;
			margin-bottom: 15px;
			width: 100%;
		}
		.add-to-cart-button {
			width: 100%;
		}
	}
</style>

