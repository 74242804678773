<template>
	<div class="">
		<h2 class="display-1 px-6 px-md-12 mb-6" v-text="$t('softCrossSells.title')"/>
		<VueSlickCarousel v-bind="settings">
			<template #prevArrow>
				<v-btn icon class="custom-arrow left-arrow">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
			</template>
			<div class="px-3 mb-3" v-for="product in crossSellProducts" :key="product.guid">
				<ProductCard
					:product="product"
					:productsSelected="productsSelected"
					is-soft-cross-sell
					@quantityChanged="updateProductQuantity"
					@quantityModifierChanged="updateModifierQuantity"
					@crossSellAddToCart="handleAddToCart"
					@crossSellShowCalendar="handleShowCalendar"/>
			</div>

			<template #nextArrow>
				<v-btn icon class="custom-arrow right-arrow">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</template>
		</VueSlickCarousel>

		<ProductPackageSelector
			v-if="productsSelected.size > 0"
			ref="ProductPackageSelector"
			:productsSelected="calendarProductFormat"
			@addToCartSuccess="handleAddToCartSuccess"/>
	</div>
</template>


<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
	import ProductCard from '@/components/ProductV2/ProductCard'
	import { WindowWidthMixin } from '@/mixins/ProductMixin'
	import ProductPackageSelector from '@/components/ProductV2/ProductPackageSelector.vue'
	import ProductV2Mixin from '@/mixins/ProductV2Mixin'

	export default {
		name: "CrossSellsCarousel",
		components: { ProductPackageSelector, ProductCard, VueSlickCarousel },
		mixins : [WindowWidthMixin, ProductV2Mixin],
		props: {
			crossSellProducts: {
				type: Array,
				default: () => ([]),
			},
			// Settings props doc https://github.com/gs-shop/vue-slick-carousel/blob/master/docs/API.md#all-props
			settings : {
				type: Object,
				default: () => ({
					centerMode: true,
					focusOnSelect : true,
					swipeToSlide: true,
					arrows : true,
					dots : false,
					centerPadding: "25%",
					infinite: true,
					slidesToShow: 1,
					speed: 500,
					adaptiveHeight : true,
					responsive : [
						{
							breakpoint: 960,
							settings: {
								centerPadding: "5%",
								dots: false
							}
						},
						{
							breakpoint: 600,
							settings: {
								centerMode: false,
								dots: false
							}
						},
					]
				}),
			}
		},
		data() {
			return {
				noProduct : true,
				currentSlide : 0,
				productsSelected:  new Map(),
				calendarProduct: null,
			}
		},
		mounted () {
			this.$root.$on('addToCartSuccess', this.handleAddToCartSuccess)
		},
		beforeDestroy () {
			this.$root.$off('addToCartSuccess', this.handleAddToCartSuccess)
		},
		computed : {
			calendarProductFormat() {
				if (!this.calendarProduct) {
					return new Map()
				}

				const data = new Map()
				data.set(this.calendarProduct.data.id, this.productsSelected.get(this.calendarProduct.data.id));

				return data;
			}
		},
		methods : {
			handleAddToCart(product) {
				const productData = this.productsSelected.get(product.data.id)
				const data = new Map()
				data.set(product.data.id, productData)
				this.addToCart(data)
			},
			handleShowCalendar(product) {
				this.calendarProduct = product;
				this.$refs.ProductPackageSelector.openModal()
			},
			handleAddToCartSuccess(products) {
				this.productsSelected.delete(products[0].productId);
				this.productsSelected = new Map(this.productsSelected);
				this.calendarProduct = null;
			}
		}
	}
</script>

<style scoped lang="scss">
.custom-arrow {
	border: 1px solid $grey-light;
	background-color: white;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}

.left-arrow {
	left:18px;
	z-index: 1;
}

.right-arrow {
	right: 18px;
}

@media (max-width: 600px) {
	.right-arrow, .left-arrow {
		top: 115px;
	}
}
</style>
