<template>
	<div class="scroll-picker pa-3">
		<scroll-picker v-model="slotId" :options="timeSlotOptions" :placeholder="placeholder" data-testid="scroll-picker" />
		<v-btn class="scroll-picker_btn mt-4" text block :disabled="!selectedTime" data-testid="scroll-picker-btn" @click="selectTime">
			<span v-text="$t('product.select_time.btn')"></span>
		</v-btn>
	</div>
</template>

<script>
	import { ScrollPicker } from 'vue-scroll-picker'

	export default {
		name: "ProductsTimeScrollPicker",
		components: {
			ScrollPicker
		},
		props: {
			timeSlots: {
				type: Array,
				required: true
			},
			isDateSelected: {
				type: Boolean,
				required: true
			},
			displayPrice : {
				type : Boolean,
				default : true,
			}
		},
		data() {
			return {
				slotId: null
			};
		},
		computed: {
			timeSlotOptions() {
				return this.timeSlots.map(slot => {
					return {
						value: slot.event_id,
						name: this.formatSlotOptionName(slot)
					}
				})
			},
			placeholder() {
				return !this.isDateSelected ? this.$i18n.t('product.select_date') : ''
			},
			selectedTime() {
				return this.timeSlots.find(slot => slot.event_id === this.slotId)
			},
			isShowingAvailabilities() {
				return this.$root.websiteConfig.data.fields.show_tickets_availabilities === "1";
			},
		},
		methods:{
			formatSlotOptionName(slot) {
				const priceContent = this.displayPrice ?
					`<span class="pa-0 text-start price">${ this.$options.filters.currencyWithoutCountry(slot.price) }</span>` : '';

				const availabilities = this.isShowingAvailabilities ?
					`<span class="pa-0 text-end places-left">${ slot.places } ${ this.$i18n.t('product.places_left') }</span>` : ''

				return `<div class="d-flex justify-space-between my-2">
							<span class="pa-0 period">${slot.period }</span>
							${priceContent}
							${availabilities}
						</div>`
			},
			selectTime(){
				this.$emit('select-time', this.selectedTime);
			}
		}
	};
</script>
<style src="vue-scroll-picker/dist/style.css"></style>
<style lang="scss" scoped>
	.scroll-picker {
		background: white;

		&_btn{
			background-color: $blackish;
			color: white;

			&.v-btn--disabled {
				background-color: $grey-light;
			}
		}
	}
</style>
<style lang="scss">
	// Modify Scroll Picker Design
	.vue-scroll-picker {
		height: 8em;

		.top {
			background: linear-gradient(180deg, #fff, rgba(255,255,255,0.3));
		}

		.bottom {
			background: linear-gradient(0deg, #fff, rgba(255,255,255,0.3));
		}

		.vue-scroll-picker-item {
			font-size: 14px;

			&.-selected {
				.period, .price {
					font-weight: bold;
				}
			}
		}
	}
</style>
