<template>
	<v-sheet color="transparent">

		<PaymentProcessOverlay
			:paying.sync="paying"
			:success.sync="success"
			:error.sync="error"
		/>

		<v-card v-if="!paymentMethod || paymentMethod === 'gift'">
			<v-card-title v-text="$t('cart.payment.choseMethod')"></v-card-title>
			<v-card-text class="button-container">
				<v-btn
					data-testid="ccPayment"
					color="button"
					class="mt-4"
					block
					:disabled="!component || fullDiscount"
					@click="handlePaymentMethod('credit')"
					x-large
				>
					<v-icon left>mdi-credit-card-outline</v-icon>
					<span v-text="$t('cart.payment.creditCard')"></span>
				</v-btn>

				<div>
					<div class="emptySpace">&nbsp;</div>
					<v-btn
						data-testid="gcPayment"
						color="button"
						class="mt-4"
						block
						:disabled="!cashless || fullDiscount"
						v-if="!giftCardIsShown"
						@click="handleGiftCardPaymentMethod('gift')"
						x-large
					>
						<v-icon left>mdi-gift-outline</v-icon>
						<span v-text="$t('cart.payment.giftCard')"></span>
					</v-btn>
					<transition name="slide" mode="out-in">
						<PaymentGiftCardForm
							v-if="giftCardIsShown && paymentMethod === 'gift'"
							:component="component"
							:cart="cart"
							:engine="engine"
							:cashless-engine="cashlessEngine"
							:payments.sync="payments"
							:skeleton="skeleton"
							:reservation="reservation"
							@cancel="handleGiftCardPaymentMethod(null)"
							@completed="handlePaymentCompleted"
							@partialGiftCardPaymentCompleted="handlePartialGiftCardPayment" />
					</transition>
				</div>

				<div v-if="accountReceivable">
					<div class="emptySpace">&nbsp;</div>
					<v-btn
						data-testid="arPayment"
						color="button"
						class="mt-4"
						block
						:disabled="!accountReceivable"
						@click="handlePaymentMethod('accountReceivable')"
						x-large
					>
						<v-icon left>mdi-cash-usd-outline</v-icon>
						<span v-text="$t('cart.payment.accountReceivable')"></span>
					</v-btn>
				</div>

				<div v-if="active_cashless">
					<div class="emptySpace">&nbsp;</div>
					<v-btn
						data-testid="cashlessPayment"
						color="button"
						class="mt-4"
						block
						:disabled="!cashless || fullDiscount"
						@click="handlePaymentMethod('cashless')"
						x-large
					>
						<v-icon left>mdi-cash-usd-outline</v-icon>
						<span v-text="$t('cart.payment.cashless')"></span>
					</v-btn>
				</div>
			</v-card-text>
		</v-card>
		<v-card v-else-if="paymentMethod === 'credit'">
			<v-card-title v-text="$t('cart.payment.enterDetails')"></v-card-title>
			<v-card-text>
				<component
					:is="component"
					:cart="cart"
					:engine="engine"
					:skeleton="skeleton"
					@completed="handlePaymentCompleted"
					@paymentOverlay="handlePaymentOverlay"
				/>
				<v-btn
					data-testid="cancelPayment"
					class="mt-4"
					x-large
					text
					block
					@click="handlePaymentMethod(null)"
				>
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-card-text>
		</v-card>
		<CashlessCard
			v-else-if="paymentMethod === 'cashless'"
			:component="component"
			:cart="cart"
			:engine="engine"
			:cashless-engine="cashlessEngine"
			:payments.sync="payments"
			:skeleton="skeleton"
			@cancel="handlePaymentMethod(null)"
			@completed="handlePaymentCompleted"
			@partialPaymentCompleted="handlePartialPaymentCompleted" />
		<AccountReceivableCard
			v-else-if="paymentMethod === 'accountReceivable'"
			:component="component"
			:cart="cart"
			:engine="engine"
			:account-receivable-engine="accountReceivableEngine"
			:payments.sync="payments"
			:skeleton="skeleton"
			@cancel="handlePaymentMethod(null)"
			@completed="handlePaymentCompleted" />

		<div v-if="verifiedBy !== null" class="mt-8 text-center">
			<img :src="verifiedBy" />
		</div>
	</v-sheet>
</template>

<style scoped>
	.slide-enter-active, .slide-leave-active {
		transition: all 1s ease;
	}
	.slide-enter, .slide-leave-to {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
	}

	.button-container button {
		margin-top: 0 !important;
	}

	.button-container .emptySpace {
		height: 22px;
	}
</style>

<script>
	import verifiedByAdyen from "@/assets/images/verified-adyen.png";
	import PaymentGiftCardForm from "./PaymentGiftCardForm.vue";
	import AccountReceivableCard from "./AccountReceivableCard";
	import CashlessCard from "./CashlessCard";
	import NmiForm from "./NmiForm";
	import PayfactoForm from "./PayfactoForm";
	import AmazonPaymentForm from "./AmazonPaymentForm";
	import AdyenForm from "./AdyenForm";
	import KonnectForm from "./KonnectForm";
	import BamboraForm from "./BamboraForm";
	import {
		EComService,
		CartModel,
		BaseModel,
		PaymentModel,
	} from "@connectngo/sdk";
	import PaymentProcessOverlay from "@/components/PaymentProcessOverlay";

	export default {
		name: "PaymentForm",

		components: { PaymentGiftCardForm, CashlessCard, PaymentProcessOverlay,AccountReceivableCard },

		props: {
			cart: {
				type: CartModel,
				default: () => new CartModel(),
			},
			payments: {
				type: PaymentModel,
				default: () => new PaymentModel(),
			},
			skeleton: {
				type: Boolean,
				default: false,
			},
			reservation: {
				type: Boolean,
				default: false,
			}
		},

		watch: {
			cart(newValue, oldValue) {
				if (
					Object.keys(newValue?.getAppliedDiscounts())?.length > 0 &&
					newValue.data?.total <= 0
				) {
					this.paymentMethod = null;
					this.fullDiscount = true;
				} else {
					this.fullDiscount = false;
				}
			},
		},

		data: () => ({
			verifiedBy: null,
			engine: new BaseModel(),
			cashlessEngine: new BaseModel(),
			cashless: false,
			accountReceivableEngine: new BaseModel(),
			accountReceivable: false,
			component: null,
			paymentMethod: null,
			fullDiscount: false,
			paying: false,
			success: false,
			error: false,
			active_cashless: false,
			active_account_receivable: false,
			giftCardIsShown: false
		}),

		methods: {
			/* Google Tag Manager */
			triggerGAEvent() {
				const items = [];
				this.cart.data.items.forEach((item, index) => {
					items.push({
						item_id: item.data.product.data.id,
						item_name: this.$options.filters.translatable(
							item.data.product.data.name,
							item.data.product.data.name_i18n,
							this.$i18n.locale
						),
						coupon: !item.data.hasDiscount ? null : this.$options.filters.translatable(
							item.data.discount.name,
							item.data.discount.name_i18n,
							this.$i18n.locale
						),
						currency:
							this.$root.websiteConfig.data.tenant.currency.code,
						discount: item.data.discountTotal,
						index: index++,
						item_category: !item.data.mainTag ? null : this.$options.filters.translatable(
							item.data.mainTag.name,
							item.data.mainTag.name_i18n,
							this.$i18n.locale
						),
						price: item.data.total,
						quantity: item.data.quantity,
					});
				});
				if (this.$gtm) {
					dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
					dataLayer.push({
						event: "add_payment_info",
						tenant_id: this.$root.websiteConfig.data.tenant.id,
						ecommerce: {
							currency:
								this.$root.websiteConfig.data.tenant.currency.code,
							value: this.cart.data.total,
							payment_type: this.paymentMethod,
							items: items,
						},
					});
				}
			},

			handleGiftCardPaymentMethod(method) {
				this.giftCardIsShown = !this.giftCardIsShown;
				this.handlePaymentMethod(method);
			},

			handlePaymentMethod(method) {
				// TO KEEP
				// To re-display the gift card button in case it was clicked before cancelling any others payments
				this.giftCardIsShown = method === null ? false : this.giftCardIsShown;

				this.paymentMethod = method;
			},

			handlePaymentOverlay(args) {
				[this.paying, this.success, this.error] = args;
			},

			handlePaymentCompleted(args) {
				this.triggerGAEvent();
				this.$emit("completed", args);
			},

			handlePartialGiftCardPayment() {
				this.paymentMethod = '';
				this.giftCardIsShown = false;

				this.handlePartialPaymentCompleted();
			},

			handlePartialPaymentCompleted() {
				this.$emit('partialPaymentCompleted');
			},

			init() {
				new EComService().getPaymentEngines().then((response) => {
					response.results.forEach((engine) => {

						let amount = this.balance ? this.balance : this.cart.data.balance || this.cart.data.total;

						if(this.cart.deposit){
							amount = this.cart.depositAmount;
						}

						this.cart.data.amountToPay = amount;

						switch (engine.data.payment_engine.name) {
							case "nmi":
								this.engine = engine;
								this.component = NmiForm;
								break;
							case "payfacto_api":
								this.engine = engine;
								this.component = PayfactoForm;
								break;
							case "adyen_ecom":
								this.component = AdyenForm;
								this.verifiedBy = verifiedByAdyen;
								this.engine = engine;
								break;
							case "konnect_ecom":
								this.component = KonnectForm;
								this.verifiedBy = verifiedByAdyen;
								this.engine = engine;
								break;
							case "bambora_ecom_iframe":
								this.component = BamboraForm;
								this.engine = engine;
								break;
							case "amazon_payment":
								this.component = AmazonPaymentForm;
								this.engine = engine;
								break;
							case "cashless":
								this.cashlessEngine = engine;
								this.cashless = true;
								break;
							case "account_receivable":
								if (this.cart.data.account?.can_receivable) {
									this.accountReceivableEngine = engine;
									this.accountReceivable = true;
								}
								break;
						}
					});
				});
			},

			isIframe() {
				try {
					return window.self !== window.top;
				} catch (e) {
					return true;
				}
			},
		},

		created() {
			this.init();
			if (
				this.$root.websiteConfig.data.fields.active_cashless?.toString() ===
				"1"
			) {
				this.active_cashless = true;
			}

			const threeDsResolutionRoutes =  ['cart_payment_3ds', 'reservation_3ds'];

			if (threeDsResolutionRoutes.includes(this.$route.name)) {
				this.paymentMethod = "credit";
				this.paying = true;
			}

			if (
				this.$route.name === "cart_payment_3ds_prepare" &&
				this.$route.params.engine === "bambora_ecom_iframe"
			) {
				if (this.isIframe()) {
					top.location.href = window.location.href;
				}
			}
		},
	};
</script>
