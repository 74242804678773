<template>
  <v-container>
		<v-row>
			<v-col cols="12">
				<template>
					<div class="text-center">
						<v-dialog
							v-model="cartConfirm"
							width="360"
						>
							<v-card>
								<v-card-title class="text-h2 text-center">
									<v-icon color="green">mdi-check-circle</v-icon>
								</v-card-title>

								<v-card-text class="text-center">
									<v-container>
										<v-row>
											<v-col cols="12">
												<h2 v-text="$t('account.reschedule.finalizeText')"></h2>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12">
												<p v-text="$t('account.reschedule.rescheduledTo', {
													event: selectedGroup ? selectedGroup.event?.event_template?.name : '',
													date: selectedDate,
												})"></p>
											</v-col>
										</v-row>
									</v-container>
								</v-card-text>

								<v-divider></v-divider>

								<v-card-actions class="text-center d-flex flex-column" style="gap:0.5rem">
									<v-btn
										v-if="changesPending"
										color="primary"
										outlined
										large
										text
										@click="switchModal"
										block
									>
										<span v-text="$t('account.reschedule.continueChanges')"></span>
									</v-btn>
									<v-spacer></v-spacer>
									<v-btn
										color="primary"
										outlined
										large
										@click="finalizeTransaction"
										:loading="savingInvoice"
										block
									>
										<span v-text="$t('account.reschedule.finalizeButton')"></span>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</div>
				</template>
				<reschedule-combo-modal
					v-if="isTimebasedCombo && featureFlagTimebasedEnabled"
					:selectedGroup="selectedGroup"
					:changeDateModal="changeDateModal"
					:selected-date="selectedDate"
					:updateSchedule="updateSchedule"
					:transactionDetailIds="transactionDetailIds"
					:product-ids="productIds"
					:selectedModifiers="selectedModifiers"
					:transactionProducts="transactionProducts"
					:event-slots="eventSlots"
					:product-slots="productSlots"
					:on-month-change="onMonthChange"
					:handle-modifier-change="handleModifierChange"
					:event-group-ids="eventGroupIds"
					:loading-colors="loadingColors"
					:on-day-change="onDayChange"
					:applying-changes="applyingChanges"
					@update-selected-timeslots="onSelectComboTimeslots"
				/>
				<Modal v-else v-model="changeDateModal.visible" footerClass="pl-6 pr-6" :title="$t('account.reschedule.changeDate')" max-width="900" :width="calanderCardWidth" scrollable>
					<template #body>
						<v-card-text>
							<h2 class="title" v-text="$t('account.reschedule.updateDateOfVisit')"></h2>
							<v-container>
								<v-row>
									<v-col cols="12" class="calander-center" :md="selectedSlot.id && (selectedSlot.price_delta > 0 || hasModifiers) && 5 || 12">
										<v-card class="mt-6" outlined :height="selectedSlot.id && (selectedSlot.price_delta > 0 || hasModifiers) && 328 || 'auto'">
											<v-date-picker
												:allowed-dates="allowedDates"
												color="primary"
												header-color="secondaryDark"
												v-model="selectedDate"
												@input="onDayChange"
												@update:picker-date="onMonthChange"
												:min="new Date().toISOString().substr(0, 10)"
												:events="functionEvents"
												:disabled="loading || loadingColors.length !== 0"
												full-width
												no-title
											>
												<div class="w-100">
													<v-expand-transition>
														<v-progress-linear v-if="loadingColors.length !== 0" indeterminate class="mb-2"></v-progress-linear>
													</v-expand-transition>
												</div>
											</v-date-picker>
										</v-card>

										<ul class="list-none py-6 ul-list-padding">
											<li class="green--text">
												<span class="black--text" v-text="$t('account.reschedule.howTo.green')"></span>
											</li>
											<li class="orange--text">
												<span class="black--text" v-text="$t('account.reschedule.howTo.orange')"></span>
											</li>
											<li class="red--text">
												<span class="black--text" v-text="$t('account.reschedule.howTo.red')"></span>
											</li>
											<li v-if="reschedule_restrict_hour && reschedule_restrict_hour != 0">
												<span class="black--text" v-text="$t('account.reschedule.howTo.point4', { hours: reschedule_restrict_hour })"></span>
											</li>
										</ul>

										<template v-if="currentSlots && currentSlots.items && currentSlots.items.length > 0">
											<h2 class="title" v-text="$t('account.reschedule.updateTimeOfVisit')"></h2>
											<v-row class="mt-3" dense>
												<span v-if="dayClosed" v-text="$t('calendar.unavailable')"></span>
												<v-alert class="w-100" v-if="dayClosed && currentSlots.items|| !currentSlots.items" text prominent type="info">
													<span v-text="$t('account.reschedule.chooseDateAlert')"></span>
												</v-alert>
												<template v-if="currentSlots.items.length < 4">
													<v-col v-for="slot in currentSlots.items" cols="12" :key="slot.id+'-'+slot.date">
														<CalendarSlot
															:period="slot.period"
															:remaining="slot.amountLeft"
															:total="slot.totalAmount"
															:selected="selectedSlot.id === slot.id"
															:color="selectedSlot.id !== slot.id ? 'secondaryDark' : 'primary'"
															:outlined="selectedSlot.id !== slot.id"
															@click="onSelectTimeSlotClick(slot)"
														/>
													</v-col>
												</template>
												<template v-else>
													<v-col  cols="12">
														<v-select  :label="$t('select.timeOfVisit')"
																   :items="slotsForSelectDisplay"
																   @change="handleSelectTimeSlotSelect"/>
													</v-col>
												</template>
											</v-row>
										</template>
									</v-col>
									<v-col cols="12" md="7" v-if="selectedSlot.id && (selectedSlot.price_delta > 0 || hasModifiers)">
										<v-card outlined class="full-height" :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: 24px' : null">
											<v-card-title class="pb-4">
												<i18n path="account.reschedule.priceDiffAvail" tag="span">
													<span class="error--text">*</span>
												</i18n>
											</v-card-title>
											<v-card-text>
												<v-card :key="productIdx" v-for="(item, productIdx) in selectedGroup.items" outlined :class="{
													'mt-3': productIdx > 0,
												}">
													<div :class="{
														'd-flex align-center': true,
													}">
														<v-img :src="item.product.image" width="150" style="border-radius: 3px 0 0 0" :aspect-ratio="16/15"></v-img>
														<v-card-text class="py-2">
															<h4 class="mb-0" v-text="$options.filters.translatable(item.product.name, item.product.name_i18n)"></h4>
															<p class="caption opacity-50 mb-2">{{ selectedSlot.period }}</p>

															<i18n path="account.reschedule.priceDiff" tag="h5">
																<strong :class="{
																	'primary--text': getProductPrice(item) > 0,
																	'error--text': getProductPrice(item) < 0,
																}">
																	<span>
																		{{ getProductPrice(item) > 0 ? '+' : '' }}
																		<span>{{ $options.filters.currency(priceDelta[item.product.id]) }}</span>
																	</span>
																</strong>
															</i18n>

															<i18n path="account.reschedule.newDate" tag="h5">
																<span class="font-weight-regular">{{ selectedSlot.day }} {{ selectedSlot.period }}</span>
															</i18n>

															<h5>
																<strong v-text="$t('account.reschedule.updatedPriceTitle')"></strong>
																&nbsp;<span class="font-weight-regular" v-text="$t('account.reschedule.updatedPriceDetails', {
																	price: $options.filters.currency(calcUpdatedPrice(item))
																})"></span>
															</h5>
														</v-card-text>
													</div>

											<v-card :key="modifierIdx" v-for="(modifier, modifierIdx) in item.timeBasedModifiers" outlined :class="{
													'mt-3': modifierIdx > 0,
												}">
													<div :class="{
														'd-flex align-center': true,
													}">
														<v-img :src="modifier.product.image" width="150" style="border-radius: 3px 0 0 0" :aspect-ratio="16/15"></v-img>
														<v-card-text class="py-2">
															<h4 class="mb-0" v-text="$options.filters.translatable(modifier.product.name, modifier.product.name_i18n)"></h4>
															<p class="caption opacity-50 mb-2"></p>

															<i18n path="account.reschedule.priceDiff" tag="h5">
																<strong :class="{
																	'primary--text': getProductPrice(modifier) > 0,
																	'error--text': getProductPrice(modifier) < 0,
																}">
																	<span>
																		{{ getProductPrice(modifier) > 0 ? '+' : '' }}
																		<span>{{ $options.filters.currency(modifierDelta[productIdx][modifierIdx]) }}</span>
																	</span>
																</strong>
															</i18n>
															<h5>
																<strong v-text="$t('account.reschedule.updatedPriceTitle')"></strong>
																&nbsp;<span class="font-weight-regular" v-text="$t('account.reschedule.updatedPriceDetails', {
																	price: $options.filters.currency(getProductPrice(modifier) + (modifierDelta[productIdx][modifierIdx] || 0))
																})"></span>
															</h5>
													<v-divider />
													<v-card-text class="pt-2" style="background-color: #f6f6f6">
														<i18n path="account.reschedule.chooseNewTime" tag="h4" class="mb-2">
															<span class="error--text">*</span>
														</i18n>
														<v-select
															v-model="modifier.period"
															:items="getModifierSlots(modifier)"
															@change="handleModifierChange($event, modifier, productIdx, modifierIdx)"
															:label="$t('account.reschedule.makeSelection')"
															background-color="white"
															dense
															outlined
															hide-details
														/>
													</v-card-text>

														</v-card-text>
													</div>
												</v-card>
												</v-card>
											</v-card-text>
											<v-card-actions>
												<div class="w-100">
													<i18n path="account.reschedule.totalPriceDiff" tag="span" class="title">
														<strong v-text="$options.filters.currency(totalDiff)"></strong>
													</i18n>
													<br>
													<i18n path="account.reschedule.lowerPriceNotRefund" tag="span" class="opacity-50">
														<span class="error--text">*</span>
													</i18n>
												</div>
											</v-card-actions>
										</v-card>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</template>

					<template #buttons>
						<v-btn color="primary" large :disabled="canConfirm" :loading="applyingChanges" @click="updateSchedule">
							<span v-text="$t('btn.confirmChanges')"></span>
						</v-btn>
						<v-btn outlined large @click="onCancelClick">
							<span v-text="$t('btn.cancel')"></span>
						</v-btn>
					</template>
				</Modal>

				<h1 class="title" v-text="$t('account.reschedule.howTo.title')"></h1>
				<ul class="mt-4">
					<li v-text="$t('account.reschedule.howTo.point1')"></li>
					<li v-text="$t('account.reschedule.howTo.point2')"></li>
				</ul>
				<h4 class="red--text text-center mt-10" v-if="_sortedTransactions.length <= 0" v-text="$t('account.reschedule.noTransactionsAvailable')"></h4>
				<v-expansion-panels v-model="transactionPanel" class="mt-8" multiple>
					<v-expansion-panel class="px-0" :key="transactionIdx" v-for="(transaction, transactionIdx) in _sortedTransactions">
						<v-expansion-panel-header>
							<TransactionCard
								:value="transaction"
								:skeleton="skeleton"
								flat
								hide-details
								:to="null"
								:qr-code-attrs="{
									size: 96
								}"
							/>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-expansion-panels v-model="panel[transactionIdx]" multiple>
								<v-expansion-panel class="px-0" :key="groupIdx" v-for="(group, groupIdx) in transaction.data.groups">
									<v-expansion-panel-header>
										<div class="d-flex align-center mr-4 column-mobile">
											<v-icon x-large>mdi-calendar</v-icon>
											<div class="ml-3" style="flex: 1">
												<h3 class="title" v-text="getEventName(group)"></h3>
												<i18n :path="getDateTimeTranslationKey(group)" tag="p" class="mb-0">
													<strong v-text="getEventDate(group)"></strong>
													<strong v-text="getEventTime(group)"></strong>
												</i18n>
												<div v-if="pending" class="d-flex align-center mt-2">
													<v-icon color="success">mdi-check-circle-outline</v-icon>
													<div class="mx-2 mb-0" v-text="$t('account.reschedule.changePending')"></div>
													(<TimestampCountdown v-model="pendingTime"></TimestampCountdown>)
												</div>
											</div>
											<v-btn color="button" @click.stop="onChangeDateClick(transaction, group, groupIdx)">
												<span v-text="$t('btn.changeDate')"></span>
											</v-btn>
										</div>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<v-row>
											<v-col :key="item.product.id + '-' + itemIdx" v-for="(item, itemIdx) in group.items" cols="12" lg="6" xl="4">
												<ProductWaiverCard :value="formatProduct(item)" :skeleton="skeleton" flat tile />
											</v-col>
										</v-row>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import moment from 'moment';
import TransactionCard from '@/components/TransactionCard';
import ProductWaiverCard from '@/components/ProductWaiverCard';
import RescheduleComboModal from '@/components/Modals/RescheduleComboModal.vue';
import TimestampCountdown from '@/components/TimestampCountdown';
import Modal from '@/components/Modal';
import CalendarSlot from '@/components/CalendarSlot';
import {BaseModel, PaginationModel, ProductModel, EComService, EventBus} from '@connectngo/sdk';
import globalVariables from "@/global";
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'

export default {
name: 'Reschedule',

	components: { TransactionCard, ProductWaiverCard, TimestampCountdown, Modal, CalendarSlot, RescheduleComboModal },
  mixins : [ReloadV2Mixin],

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_reschedule'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_reschedule') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

    mounted() {
		this.arrayEvents = [...Array(6)].map(() => {
			const day = Math.floor(Math.random() * 30)
			const d = new Date()
			d.setDate(day)
			return d.toISOString().substr(0, 10)
		})
    },

	data: () => ({
		now: new Date().getTime(),
		loading: false,
		loadingColors: [],
		applyingChanges: false,
		transactions: new PaginationModel([{}, {}, {}]),
		transactionDetailIds: [],
		selectedTransaction: new BaseModel(),
		selectedGroup: null,
		productSlots: [],
		eventSlots: [],
		productIds: [],
		currentSlots: [],
		mappedData: [],
		priceDelta: {},
		dayClosed: false,
		calendarDataForCurrentMonth: {},
		isSlotSelected: false,
		selectedDate: null,
		selectedSlot: {},
		selectedModifiers: [],
		allModifiersSelected: false,
		modifierDelta: [],
		selectedComboTimeslots: [],
		products: new PaginationModel([], ProductModel),
		skeleton: true,
		transactionPanel: [],
		panel: {},
		changeDateModal: {
			visible: false,
		},
		eventGroupIds: [],
		cartConfirm: false,
		totalDiff: 0,
		savingInvoice: false,
		dateChangeCompleted: {},
		pending: false,
		pendingTime: 0,
		hasModifiers: false,
		changesPending: true,
		groupIndex: 0,
		reschedule_restrict_hour: 0,
		reschedule_allow_hour: 0
	}),

	computed: {
		_sortedTransactions() {
			return this.transactions.results ? this.transactions.results?.reverse() : [];
		},
		canApplyChanges() {
			return !(this.loading || this.applyingChanges || !this.selectedDate || !this.isSlotSelected) ? true : false;
		},
		hasPriceChangeDifference() {
			return this.selectedSlot.price_delta > 0;
		},
		canConfirm() {
			return !this.canApplyChanges || !this.allModifiersSelected || !this.isSlotSelected;
		},
		calanderCardWidth() {
			return !(this.selectedSlot?.id && (this.selectedSlot?.price_delta > 0 || this.hasModifiers)) && '500' || 'auto';
		},
		showAvailability() {
			return !!parseInt(globalVariables.websiteConfig.data.fields.show_tickets_availabilities) ?? false;
		},
		slotsForSelectDisplay() {
			return this.currentSlots.items.map(item => {
				let label = item.period;

				if(this.showAvailability) {
					label += ` (${this.$tc('calendar.places', item.amountLeft, {
						amount: item.amountLeft
					})})`;
				}

				return {
					value : item,
					disabled : item.amountLeft <= 0,
					text : label,
				}
			})
		},
		isTimebasedCombo() {
			return this.selectedGroup?.is_combo;
		},
		featureFlagTimebasedEnabled() {
			const { feature_flags } = this.$root.websiteConfig.data || {};
			return feature_flags.includes('co-5754-time-based-combos');
		}
	},
	methods: {
		calculateTotal() {
			if (!this.isSlotSelected) return 0;

			let diff = 0;

			this.selectedGroup.items.forEach((item, itemIdx) => {
				diff += this.priceDelta[item.product.id];

				item.timeBasedModifiers.forEach((modifier, modifierIdx) => {
					if (this.priceDelta[modifier.product.id] && this.selectedModifiers[itemIdx][modifierIdx]) {
						diff += this.priceDelta[modifier.product.id];
					}
				})

			});
			this.totalDiff = diff;
		},
		getProductPrice(item) {
			if (this.selectedGroup !== null) {
				for (let i in this.selectedGroup.items) {
					const currentItem = this.selectedGroup.items[i];

					if (currentItem.id === item.id && currentItem.unit_amount) {
						return currentItem.unit_amount;
					}
				}
			}

			const product = item.product

			if (product.price) {
				return product.price.min_price;
			}

			return 0;
		},
		functionEvents(date) {
			const todayDate = moment().format('YYYY-MM-DD');
			const currentTimeStamp = moment().unix();
			if (moment(date).isBefore(todayDate) || !this.productSlots.length || this.loadingColors.length) {
				return;
			}
			const currSlots = this.productSlots.find(d => d.data.day === date);

			if (!currSlots){
				return;
			}

			if (todayDate === date) {
				const lastEventStartTime = currSlots.data.events[currSlots.data.events?.length - 1]?.start_ts;
				if (!moment(currentTimeStamp).isBefore(lastEventStartTime)) {
					return;
				}
			}

			if (currSlots.data.events.find(e => e.price_delta > 0)) return 'orange';

			return currSlots.data.color;
		},
		onDayChange(date) {
			this.isSlotSelected = false;
			this.selectedSlot = {};
			this.selectedDate = date;
			this.currentSlots = this.mappedData.find(d => d.day === date);

			if (!this.currentSlots?.items?.length) {
				this.dayClosed = true;
			} else {
				this.dayClosed = false;
			}
		},
		onMonthChange(date) {
			this.calendarDataForCurrentMonth = {};
			this.allModifiersSelected = false;
			if (!this.isValidDate(date)) {
				this.selectedDate = null;
			}

			this.getReschedulableEvents(date);
			this.getEventAvailabilities(date);
		},
		isValidDate(date) {
			const regex = /^\d{4}-\d{2}-\d{2}$/;
			return regex.test(date);
		},
		handleSelectDate(date) {
			let selectDate = moment.unix(date).format('YYYY-MM-DD');
			if (this.reschedule_allow_hour && this.reschedule_allow_hour != 0) {
				let currentAvailableDate = moment().add(this.reschedule_allow_hour + 24, 'hours').format('YYYY-MM-DD');
				if (moment(selectDate).isBefore(currentAvailableDate)) {
					return currentAvailableDate;
				}
			}
			return selectDate;
		},
		onChangeDateClick(transaction, group, groupIdx) {
			this.loadingColors.push(true);
			this.changeDateModal.visible = true;
			this.selectedTransaction = transaction;
			this.transactionProducts = [];
			this.selectedGroup = group;
			this.groupIndex = groupIdx;
			this.selectedSlot = {};
			this.calendarDataForCurrentMonth = {};
			if (group.is_combo) {
				this.selectedDate = this.handleSelectDate(group.items?.[0]?.event?.start_datetime);
			} else {
				this.selectedDate = this.handleSelectDate(group.event?.start_datetime);
			}
			this.transactionDetailIds = [];
			this.productSlots = [];
			this.productIds = [];
			this.currentSlots = [];
			this.mappedData = [];
			this.priceDelta = {};
			this.isSlotSelected = false;
			this.selectedModifiers = [];
			this.eventGroupIds = [];
			this.allModifiersSelected = false;
			this.modifierDelta = [];
			this.totalDiff = 0;
			this.hasModifiers = false;
			this.dateChangeCompleted[this.selectedTransaction.data.id] ??= [];

			this.selectedGroup.items.forEach((item, index) => {
				if (this.productIds.indexOf(item.product.id) === -1) {
					this.productIds.push(item.product.id)
					this.priceDelta[item.product.id] = 0;
				}

				this.transactionProducts.push(item.product);

				if (this.isTimebasedCombo && item.product.is_combo) {
					this.transactionDetailIds.push(item.id);
				} else if (!this.isTimebasedCombo && this.transactionDetailIds.indexOf(item.id) === -1) {
					this.transactionDetailIds.push(item.id);
				}

				if (
					item?.product.event_group?.id &&
					this.eventGroupIds.indexOf(item?.product?.event_group?.id) === -1
				) {
					this.eventGroupIds.push(item?.product?.event_group?.id);
				}

				this.modifierDelta[index] = [];
				this.selectedModifiers[index] = [];

				item.timeBasedModifiers.forEach((modifier, modIdx) => {
					if (this.transactionDetailIds.indexOf(modifier.id) === -1) {
						this.transactionDetailIds.push(modifier.id);
					}

					if (this.productIds.indexOf(modifier.product.id) === -1) {
						this.productIds.push(modifier.product.id);
						this.priceDelta[modifier.product.id] = 0;
					}

					if (this.eventGroupIds.indexOf(modifier.product.event_group.id) === -1) {
						this.eventGroupIds.push(modifier.product.event_group.id);
					}

					this.selectedModifiers[index][modIdx] = false;
					this.modifierDelta[index][modIdx] = 0;
				});
			});

			this.getReschedulableEvents(this.selectedDate);
			this.getEventAvailabilities(this.selectedDate);
			this.loadingColors.pop();
		},
		onCancelClick() {
			this.changeDateModal.visible = false;
		},
		onSelectTimeSlotClick(slot) {
			this.selectedSlot = slot;
			this.isSlotSelected = true;
			let modsOn = true;
			let hasMods = false;

			this.selectedGroup.items.forEach(item => {
				if (item.timeBasedModifiers.length > 0) {
					modsOn = false;
					hasMods = true;
				}
			});

			this.allModifiersSelected = modsOn;
			this.hasModifiers = hasMods;

			return new EComService().getProductPrices(this.selectedSlot.id, this.productIds)
				.then(response => {
					const deltas = {};
					this.selectedGroup.items.forEach(item => {
						if (response.data[item.product.id]) {
							deltas[item.product.id] = response.data[item.product.id].min_price - this.getProductPrice(item);
						}

						item.timeBasedModifiers.forEach(modifier => {
							deltas[modifier.product.id] = response.data[modifier.product.id].min_price - this.getProductPrice(modifier);
						})
					});

					this.priceDelta = deltas;
					this.calculateTotal();
				})
				.finally(() => this.loading = false);
		},
		allowedDates(val) {
			return val >= moment().format('YYYY-MM-DD') && this.functionEvents(val) && this.functionEvents(val) !== 'red';
		},
		eventDate(eventDate) {
			return moment.unix(eventDate).format('LL');
		},
		eventTime(startTime, endTime) {
			let eventDate = '';
			const format = 'LT';
			const multiplyBy = 1000;

			eventDate += this.$options.filters.tsToFormat(startTime * multiplyBy, format, true);

			if (endTime) {
				eventDate += ' - ' + this.$options.filters.tsToFormat(endTime * multiplyBy, format, true);
			}

			return eventDate;
		},
		formatProduct(item) {
			return new BaseModel(item);
		},
		handleModifierChange(event, modifier, itemIdx, modifierIdx) {
			this.selectedModifierSlot = this.getMappedData(this.calendarDataForCurrentMonth[modifier.product.event_group.id])
				.find(d => d.date === this.selectedDate).items
				.find(item => item.period === event)

			return new EComService().getProductPrices(this.selectedModifierSlot.id, this.productIds)
				.then(response => {
					const modDelta = this.modifierDelta.slice(0);
					const selectedMods = this.selectedModifiers.slice(0);
					let allSelected = true;

					modDelta[itemIdx][modifierIdx] = response.data[modifier.product.id].min_price - this.getProductPrice(modifier);
					selectedMods[itemIdx][modifierIdx] = this.selectedModifierSlot.id;

					this.selectedModifiers.forEach((i, ix) => {
						this.selectedModifiers[ix].forEach((m, mx) => {
							if (!this.selectedModifiers[ix][mx]) {
								allSelected = false;
								return;
							}
						});
					});

					this.allModifiersSelected = allSelected;
					this.modifierDelta = modDelta;
					this.selectedModifiers = selectedMods;
					this.calculateTotal();
				})
				.finally(() => this.loading = false);
		},
		getModifierSlots(modifier) {
			if (!this.calendarDataForCurrentMonth[modifier.product.event_group.id] || !this.getMappedData(this.calendarDataForCurrentMonth[modifier.product.event_group.id]).find(d => d.date === this.selectedDate)) {
				return [];
			}

			return this.getMappedData(this.calendarDataForCurrentMonth[modifier.product.event_group.id]).find(d => d.date === this.selectedDate).items.map(i => i.period);
		},
		updateSchedule(){
			this.applyingChanges = true;
			const addItems = [];

			// Iterate over items in the group
			this.selectedGroup.items.forEach((item, itmIdx) => {
				const modifiers = [];
				item.timeBasedModifiers.forEach((modifier, modifierIdx) => {
					modifiers.push({
						transactionDetailId: modifier.id,
						productId: modifier.product.id,
						eventId: this.selectedModifiers[itmIdx][modifierIdx],
					})
				});

				item.modifiers.forEach(modifier => {
					modifiers.push({
						transactionDetailId: modifier.id,
						productId: modifier.product.id,
						eventId: null
					})
				})

				let selectedTimeslotEventId = this.selectedSlot.id;
				const itemIsTimeBasedComboChild = (item.comboId !== null) && (item.product.event_group !== null);

				if (itemIsTimeBasedComboChild) {
					selectedTimeslotEventId = this.selectedComboTimeslots[item.product.id]?.id;
				}

				addItems.push({
					transactionDetailId: item.id,
					productId: item.product.id,
					eventId: selectedTimeslotEventId,
					modifiers: modifiers,
				});
			});

			this.dateChangeCompleted[this.selectedTransaction.data.id].push(this.groupIndex);

			if (this.dateChangeCompleted[this.selectedTransaction.data.id].length === this.selectedTransaction.data.groups.length) {
				this.changesPending = false;
			}

			this.pendingTime = new Date().getTime() + 60000 * 30;

			return new EComService().addReschedulableItems(addItems)
				.then((model) => {
					this.pending = true;
					globalVariables.cart = model;
					EventBus.publish('CART_UPDATED', model);
					this.changeDateModal.visible = false;
					this.cartConfirm = true;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => {
					this.applyingChanges = false;
				});
		},
		finalizeTransaction() {
			if (this.$root.cart.data.subTotal > 0) {
				this.$router.push({ name: 'cart' });
			} else {
				this.saveInvoice();
			}
		},
		saveInvoice() {
			this.savingInvoice = true;
			return new EComService().patchCart({'account_id': this.$root.user.data.id})
				.then(() => {
					return new EComService().saveInvoice(this.$root.cart.data.id)
						.then(invoice => {
							//this.triggerGAEvent(invoice);
							const createCartCb = () => {
								return new EComService().createCart().then(cart => {
									this.$root.cart = cart;
									EventBus.publish('CART_UPDATED', cart);
									const nextStep = !this.reloadV2Enabled && this.$root.websiteConfig.data.fields.active_reload === "1" ? 'reload' : 'confirmation';
									this.$router.push({ name: 'invoice_step', params: { uuid: invoice.data.invoice.uuid, step: nextStep } });
								});
							}
							this.cartConfirm = false;
							return invoice.data.need_fulfillment
								? new EComService().autoFulfillInvoice(invoice.data.invoice.uuid).then(createCartCb)
								: createCartCb();
						})
				})
				.catch(error => this.$handleError(this, error))
				.finally(() => this.savingInvoice = false);
		},
		getReschedulableEvents(date) {
			this.loadingColors.push(true);
			const from = moment(date).startOf('month').startOf('day').unix();
			const to = moment(date).endOf('month').endOf('day').unix();

			return new EComService().getReschedulableEvents(
				this.transactionDetailIds,
				from,
				to,
			)
				.then((events) => {
					this.productSlots = events.results;
					this.mappedData = this.getMappedData(this.productSlots);

				})
				.finally(() => {
					this.loadingColors.pop();
				});
		},
		onSelectComboTimeslots(ts) {
			this.selectedComboTimeslots = ts;
		},
		getEventAvailabilities(date) {
			const from = moment(date).startOf('month').startOf('day').unix();
			const to = moment(date).endOf('month').endOf('day').unix();

			// Remove undefined and duplicated values
			const eventGroupIds = [...new Set(this.eventGroupIds.filter(Boolean))];

			const promises = eventGroupIds.map(eventGroupId => {
				return new EComService().getEventAvailabilities(
					eventGroupId,
					from,
					to,
				)
					.then(events => {
						this.calendarDataForCurrentMonth[eventGroupId] = events;
						const formattedEvents = events.map(event => ({
							...event,
							data: {
								...event.data,
								event_group_id: eventGroupId,
							}
						}))
						return formattedEvents;
					})
					.finally(() => {
					//	this.loadingColors = false;
					});
			});
			return Promise.all(promises).then(allResults => {
				this.eventSlots = allResults.flat();
			});
		},
		getMappedData(slots = []) {
			return slots.map(item => ({
				day: item.data.day,
				date: item.data.day,
				state: item.data.closed ? 'closed' : 'open',
				color: item.data.color,
				items: item?.data?.events?.map(event => {
					const eventData = event instanceof BaseModel ? event.data : event;

					return {
						id: eventData.id,
						day: eventData.day || item.data.day,
						period: eventData.period,
						amountLeft: eventData.available || 0,
						totalAmount: eventData.available || 0,
						price_delta: eventData.price_delta || 0,
					}
				})
			}));
		},
		switchModal() {
			this.cartConfirm = false;
			this.changeDateModal.visible = false;
			for (let i = 0; i < this.selectedTransaction.data.groups.length; i++) {
				if (!this.dateChangeCompleted[this.selectedTransaction.data.id].includes(i)) {
					setTimeout(this.onChangeDateClick(this.selectedTransaction, this.selectedTransaction.data.groups[i], i), 1000); // Smoothen transition
					return;
				}
			}
		},
		getEventName(group) {
			if (group.is_combo) {
				const combo = group.items.find(item => item.product && item.product.is_combo === true);
				return this.$options.filters.translatable(
						combo.product.name,
						combo.product.name_i18n,
						this.$i18n.locale
				);
			}
			return group.event?.event_template.name;
		},
		getDateTimeTranslationKey(group) {
			if (group.is_combo) {
				return "account.reschedule.comboVisitDate";
			}
			return "account.reschedule.visitDate";
		},
		getEventDate(group) {
			if (group.is_combo) {
				return this.eventDate(group.items?.[0]?.event?.start_datetime);
			}
			return this.eventDate(group.event?.start_datetime);
		},
		getEventTime(group) {
			if (group.is_combo) {
				return this.eventTime(group.items?.[0]?.event?.start_datetime, group.items?.[0]?.event?.end_datetime);
			}
			return this.eventTime(group.event?.start_datetime, group.event?.end_datetime);
		},
		load() {
			this.loading = true;

			return new EComService().getReschedulableInvoices()
				.then((transactions) => {
					Object.assign(this, { transactions });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => {
					this.loading = false;
					this.skeleton = false;
				});
		},
		handleSelectTimeSlotSelect(slot) {
			this.onSelectTimeSlotClick(slot);
		},
		calcUpdatedPrice(item) {
			return parseFloat(this.getProductPrice(item)) + (this.priceDelta[item.product.id] || 0);
		}
	},
	created() {
		this.reschedule_restrict_hour = parseInt(globalVariables.websiteConfig.data.fields?.reschedule_restrict_hour);
		this.reschedule_allow_hour = parseInt(globalVariables.websiteConfig.data.fields?.reschedule_allow_hour);
		this.$route.meta.section = this.$i18n.t('route.account_reschedule');
		this.load();
	},
}
</script>

<style>
.v-date-picker-table {
	min-height: 250px;
}

.calander-center {
    margin: auto;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.ul-list-padding {
	padding-left: 13px !important;
}

@media all and (max-width: 767px) {
	.column-mobile {
		flex-wrap: wrap;
		justify-content: center;
	}
	.column-mobile button{
		margin-top: 20px
	}
}



</style>
