<template>
	<div :class="['sticky-container', active ? 'active' : '']">
		<div class="row">
			<v-col cols="12" md="5" v-if="!isMobile"></v-col>
			<v-col cols="12" md="7">
					<div class="btn-container">
						<v-btn
							elevation="0"
							class="next-btn"
							block
							large
							:disabled="nextDisabled"
							@click="$emit('nextClicked')">
							<span v-text="nextLabel"></span>
						</v-btn>
					</div>
			</v-col>
		</div>
	</div>
</template>

<script>
import { WindowWidthMixin } from '@/mixins/ProductMixin';
export default {
	name : 'StickyBottomActions',
	mixins: [WindowWidthMixin],
	props : {
		active : {
			type : Boolean,
			default : false,
		},
		nextLabel : {
			type : String,
			required : true,
		},
		nextDisabled : {
			type : Boolean,
			default : false,
		},
		backLabel : {
			type : String,
			required : true,
		},
		backDisabled : {
			type : Boolean,
			default : false,
		},
	},
}
</script>


<style scoped lang="scss">
	.btn-container {
		width: 50%;
		margin-left: auto;
		margin-right: 16px;
	}
	.next-btn {
		background-color: $grey-dark !important;
		color: $grey-lighter!important;
		padding: 0;
		width: 50% !important;
	}
	.sticky-container {
		position: relative;
		background-color: white;
		margin-top: 0 !important;
		padding: 20px 25px;
	}
	.sticky-container.active{
		position: sticky;
		position: -webkit-sticky;
		bottom: 0%;
		z-index: 2;
		box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.05);
	}
	.sticky-container .button-container v-btn {
		width: 50%;
	}
	@media all and (max-width: 620px) {
		.sticky-container .button-container button, .sticky-container .button-container a{
			font-size: 0.7rem;
		}

		.btn-container {
			width: 100%;
			margin: 0;
		}
	}
</style>
