<template>
	<v-container data-testid="billingForm" v-bind="$attrs" v-on="$listeners">
		<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions"></v-skeleton-loader>
		<template v-else-if="!isLoggedIn">
			<v-card>
				<KYC background-color="white" @completed="handleKYCCompleted" :showContinueAsGuestTab="true" />
			</v-card>
		</template>
		<template v-else-if="!hasCompletedRequiredFields">
			<div class="mb-8 text-center">
				<h1 class="text-h4 mb-8" v-text="$t('cart.identification.requiredFields.title')"></h1>
				<p v-text="$t('cart.identification.requiredFields.desc')"></p>
			</div>
			<v-card>
				<v-card-text>
					<CustomField
						v-for="field in customFields.results"
						v-model="$root.user.data.fields[field.data.name]"
						:key="field.data.id"
						:field="field"
						:label="$options.filters.translatable(field.data.name, field.data.name_i18n, $i18n.locale)"
						:error-messages="formErrors[field.data.name]"
						:required="field.data.required"
						class="pt-0 mt-0"
						outlined
						inset
					/>

					<v-btn color="button" block :loading="loading" :disabled="loading" @click="handleRequiredFieldsCompleted">
						<span v-text="$t('btn.continue')"></span>
					</v-btn>
				</v-card-text>
			</v-card>
		</template>
		<template v-else-if="!hasCompletedBillingInfo">
			<div class="mb-8 text-center">
				<h1 class="text-h4 mb-8" v-text="$t('cart.identification.billingInfo.title')"></h1>
				<p v-text="$t('cart.identification.billingInfo.desc')"></p>
			</div>
			<EditBillingInfoCard
				:value="$root.user"
				:btn-text="$t('btn.continue')"
				:btn-icon="null"
				no-snack
				@completed="handleBillingInfoCompleted"
			/>
		</template>
	</v-container>
</template>

<script>
	import KYC from "@/components/KYC";
	import CustomField from "@/components/CustomField";
	import EditBillingInfoCard from "@/components/EditBillingInfoCard";
	import {
		CartModel,
		EComService,
		EventBus,
		PaginationModel,
		PaymentModel,
	} from "@connectngo/sdk";
	import globalVariables from "@/global";
	import * as Sentry from "@sentry/vue";

	export default {
		name: "Identification",

		components: { EditBillingInfoCard, KYC, CustomField },

		props: {
			cart: {
				type: CartModel,
				default: () => new CartModel(),
			},
			payments: {
				type: PaymentModel,
				default: () => new PaymentModel(),
			},
		},

		data: () => ({
			loading: false,
			skeleton: true,
			formErrors: {},
			customFields: new PaginationModel(),
			completedBillingInfo: false,
		}),

		computed: {
			isLoggedIn() {
				return this.$root.user.data.id && !this.$root.user.data.is_guest;
			},
			hasCompletedRequiredFields() {
				return this.customFields.results.length === 0;
			},
			hasCompletedBillingInfo() {
				// Guest do not need to enter their billing address
				return this.$root.user.data.is_guest || this.completedBillingInfo;
			},
		},

		methods: {
			/* Google Tag Manager */
			triggerGAEvent() {
				const items = [];
				this.cart.data.items.forEach((item, index) => {
					items.push({
						item_id: item.data.product.data.id,
						item_name: this.$options.filters.translatable(
							item.data.product.data.name,
							item.data.product.data.name_i18n,
							this.$i18n.locale
						),
						coupon: item.data.hasDiscount
							? this.$options.filters.translatable(
									item.data.discount.name,
									item.data.discount.name_i18n,
									this.$i18n.locale
							)
							: null,
						currency:
							this.$root.websiteConfig.data.tenant.currency.code,
						discount: item.data.discountTotal,
						index: index++,
						item_category: item.data.mainTag
							? this.$options.filters.translatable(
									item.data.mainTag.name,
									item.data.mainTag.name_i18n,
									this.$i18n.locale
							)
							: null,
						price: item.data.total,
						quantity: item.data.quantity,
					});
				});
				if (this.$gtm) {
					dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
					dataLayer.push({
						event: "add_shipping_info",
						tenant_id: this.$root.websiteConfig.data.tenant.id,
						ecommerce: {
							currency:
								this.$root.websiteConfig.data.tenant.currency.code,
							value: this.cart.data.total,
							items: items,
						},
					});
				}
			},

			handleKYCCompleted() {
				this.filterRequiredCustomFields(this.customFields);
			},
			handleRequiredFieldsCompleted() {
				this.loading = true;

				const fieldsToUpdate = this.customFields.results.reduce(
					(a, field) => ({
						...a,
						[field.data.name]:
							this.$root.user.data.fields[field.data.name],
					}),
					{}
				);
				new EComService()
					.patchAccount(this.$root.user.data.id, {
						fields: fieldsToUpdate,
					})
					.then((response) => {
						this.load();
					})
					.catch((reason) => this.$handleError(this, reason))
					.finally(() => (this.loading = false));
			},
			handleBillingInfoCompleted() {
				this.completedBillingInfo = true;
				try {
					this.triggerGAEvent();
				} catch (e) {
					Sentry.captureException(e);
				}
				this.$emit("next");
			},
			load() {
				let pending = 0;
				this.loading = true;

				pending++;
				new EComService().getCustomFields()
					.then((customFields) => {
						this.filterRequiredCustomFields(customFields);
					})
					.catch((reason) => {
						this.$handleError(this, reason);
					})
					.finally(() => {
						pending--;
						this.loading = pending === 0;
						this.skeleton = !this.loading;
					});

				if (this.$root.user.data.id) {
					pending++;
					new EComService().getCart()
						.then((cartData) => {
							if (cartData.data.account_id == null) {
								pending++;
								this.patchCartAccountId();
							} else {
								EventBus.publish('CART_UPDATED', cartData)
								globalVariables.cart.data = cartData
								this.$root.cart = cartData
							}
						})
						.catch((reason) => {
							console.error(reason);
							if (new EComService().isMissingRequiredFields(reason)) {
								EventBus.publish('MISSING_ACCOUNT_FIELDS', reason.fields);
							} else {
								this.$handleError(this, reason)
							}
						})
						.finally(() => {
							pending--;
							this.loading = pending === 0;
							this.skeleton = !this.loading;
						});
				}
			},
			filterRequiredCustomFields(customFields) {
				Object.assign(this, {
					customFields: new PaginationModel(
						customFields.results.filter(
							(field) =>
								field.data.required &&
								(this.$root.user.data.fields[field.data.name] === undefined
									|| this.$root.user.data.fields[field.data.name] === null)
						)
					),
				});
			},
			patchCartAccountId() {
				this.loading = true;
				this.skeleton = true;
				new EComService()
					.patchCart({ account_id: this.$root.user.data.id })
					.then((data) => {
						EventBus.publish("CART_UPDATED", data);
						globalVariables.cart.data = data;
						this.$root.cart = data;
					})
					.catch((e) => {
						this.$handleError(this, e);
					})
					.finally(() => (this.loading = this.skeleton = false));
			},
		},
		watch: {
			isLoggedIn(newval, oldval) {
				if (newval !== null) {
					this.patchCartAccountId();
				}
			},
		},
		created() {
			this.load();
		},
	};
</script>

<style scoped lang="scss">
h3 {
	font-size: 36px;
}

</style>
