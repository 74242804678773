<template>
	<v-skeleton-loader v-if="skeleton" type="article,article,article,article" />
	<v-card v-else v-bind="$attrs" v-on="$listeners">
		<v-card-title :class="{'font-weight-bold': cart.data.type === 'RESERVATION'}">
			<span v-text="cart.data.type === 'RESERVATION' ? $t('summaryOrderCard.reservation.title') : $t('summaryOrderCard.title')"></span>
		</v-card-title>
		<v-card-text class="text-left blackish-text" :class="{'pa-0': cart.data.type === 'RESERVATION'}">

			<!-- ITEMS -->
			<v-alert v-if="cart.data.items.length === 0" type="info" outlined>
				<span v-text="$t('summaryOrderCard.noItems')"></span>
			</v-alert>
			<template v-else v-for="item in !modifierFfEnabled ? cart.data.items : itemsWithModifierGrouped">
				<v-row :key="item.guid" dense>
					<v-col cols="12">
						<h4 :class="{'font-weight-bold black--text text-capitalize d-inline-block': showDepositSection}" v-text="$options.filters.translatable(item.data.product.data.name, item.data.product.data.name_i18n)"></h4>
						<p v-if="showDepositSection && cart.data.reservation_date" class="d-inline-block font-weight-medium black--text" v-html="reservationDate"></p>
					</v-col>
					<v-col cols="6" class="grayish-text">
						<span v-text="item.data.quantity"></span>
						x <span v-text="$options.filters.currencyWithoutCountry(item.data.unitPrice)"></span>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right">
						<span v-text="$options.filters.currencyWithoutCountry(item.data.quantity * item.data.unitPrice)"></span>
					</v-col>
				</v-row>
				<v-row :key="modifier.guid" v-for="modifier in item.data.modifiers" dense class="ml-5 grayish-text">
					<v-col cols="12">
						<h4 :class="{'font-weight-bold black--text text-capitalize': showDepositSection}"  v-text="$options.filters.translatable(modifier.data.product.data.name, modifier.data.product.data.name_i18n)"></h4>
					</v-col>
					<v-col cols="6">
						<span v-text="modifier.data.quantity" ></span>
						x <span v-text="$options.filters.currencyWithoutCountry(modifier.data.unitPrice)"></span>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right">
						<span v-text="$options.filters.currencyWithoutCountry(modifier.data.quantity * modifier.data.unitPrice)"></span>
					</v-col>
				</v-row>
			</template>

			<!-- SUB-TOTAL -->
			<div v-if="!$root.websiteConfig.data.tenant.tax_included">
				<v-divider class="my-4" />
				<v-row dense>
					<v-col cols="6">
						<h4 :class="{'font-weight-bold black--text': showDepositSection}" v-text="$t('summaryOrderCard.subTotal')"></h4>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right">
						<span v-text="$options.filters.currencyWithoutCountry(cart.data.subTotal - cart.data.discountTotal)"></span>
					</v-col>
				</v-row>
			</div>

			<!-- REBATES -->
			<v-divider v-if="Object.keys(cart.getAppliedDiscounts()).length !== 0" class="my-4" />
			<v-row :key="cart.getAppliedDiscounts()[discountKey].guid" v-for="discountKey in Object.keys(cart.getAppliedDiscounts())" dense>
				<v-col cols="8">
					<h4 :class="{'font-weight-bold black--text text-capitalize': showDepositSection}" v-text="$options.filters.translatable(cart.getAppliedDiscounts()[discountKey].name, cart.getAppliedDiscounts()[discountKey].name_i18n, $i18n.locale)"></h4>
					<p class="ma-0">
						<span v-text="$t('rebateCard.applied.code', {
							code: cart.getAppliedDiscounts()[discountKey].code
						})"></span>
						<v-tooltip bottom>
							<template #activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" class="ml-2" color="secondaryDark" icon small @click="handleRebateInput(cart.getAppliedDiscounts()[discountKey])">
									<v-icon small>mdi-trash-can-outline</v-icon>
								</v-btn>
							</template>
							<span v-text="$t('summaryOrderCard.removeRebate')"></span>
						</v-tooltip>
					</p>
				</v-col>
				<v-col cols="4" class="font-weight-bold text-right">
					<span v-text="$options.filters.currencyWithoutCountry(cart.getAppliedDiscounts()[discountKey].totalApplied)"></span>
				</v-col>
			</v-row>

			<!-- TOTAL BEFORE TAXES -->
			<template v-if="cart.data.taxes.length > 0">
				<!-- TAXES -->
				<v-divider class="my-4" />
				<v-row :key="tax.guid" v-for="tax in cart.data.taxes" dense>
					<v-col cols="6">
						<h4 class="font-weight-regular">
							<span v-text="tax.data.taxDetail.name"></span>
							(<span v-text="$options.filters.taxes(tax.data.taxDetail.rate)"></span>)
						</h4>
						<p class="ma-0" v-text="tax.data.taxDetail.number"></p>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right">
						<span v-text="$options.filters.currencyWithoutCountry(tax.data.total)"></span>
					</v-col>
				</v-row>
			</template>

			<!-- CART PAYMENTS -->
			<div v-if="showCartPayments && payments.data.payments.length > 0" class="my-2 mx-0">
				<v-row dense v-for="payment in payments.data.payments" :key="payment.data.id">
					<v-col cols="6">
						<div v-text="$options.filters.currencyWithoutCountry(payment.data.amount) + ' ' + $t('summaryOrderCard.rebateApplied')"></div>
						<div>
							<span v-text="getPaymentMethodName(payment.data.paymentMethod)"></span>
							<span>
								<RemoveButton :paymentId="payment.data.id" :emitValue="'removePayment'" @removePayment="removePayment(payment.data.id)" />
							</span>
						</div>
					</v-col>
					<v-col cols="6" class="font-weight-bold text-right green--text">
						<span v-text="$options.filters.currencyWithoutCountry(-payment.data.amount)"></span>
					</v-col>
				</v-row>
			</div>

			<!-- TOTAL -->
			<v-divider class="my-4" />
			<!-- DEPOSIT PAYMENTS -->
			<v-row dense>
				<v-col cols="6">
					<h4 :class="{'font-weight-bold black--text text-capitalize': showDepositSection}" v-text="$t('summaryOrderCard.cartTotal')"></h4>
				</v-col>
				<v-col cols="6" class="font-weight-bold text-right">
					<span v-text="cart.data.type === 'CART' ? $options.filters.currencyWithoutCountry(cart.data.balance) :  $options.filters.currencyWithoutCountry(cart.data.total)"></span>
				</v-col>
				<v-btn v-if="(showContinueButton)"
					class="mt-6" color="button" block :loading="skeleton" :disabled="skeleton" @click="load">
						<span v-text="$t('btn.continue')"></span>
				</v-btn>
			</v-row>
		</v-card-text>

		<!-- DEPOSIT -->
		<div v-if="showDepositSection && payments.data.payments.length > 0" class="pt-16 mx-0 mb-11 top-border">
			<v-card-title class="font-weight-bold" v-text="$t('reservation.payment_title')"></v-card-title>
			<v-card-text class="pa-0">
				<v-row :key="payment.data.id" v-for="payment in payments.data.payments" class="black--text subtitle-1 open-sans-font">
					<v-col cols="6" class="text-left">{{ $options.filters.timestampToDateOnly(payment.data.created_at) }} <span v-if="payment.data.payment_method">({{$options.filters.translatable(payment.data.payment_method.name, payment.data.paymentMethod.name_i18n, $i18n.locale)}})</span></v-col>
					<v-col cols="6" class="text-right" v-text="$options.filters.currencyWithoutCountry(payment.data.amount)"></v-col>
				</v-row>
				<v-row class="black--text font-weight-bold subtitle-1 open-sans-font bottom-border top-border">
					<v-col cols="6" class="text-left"  v-text="cart.data.status ==='CANCELLED' && $t('reservation.amount_refunded') || $t('summaryOrderCard.balance')"></v-col>
					<v-col cols="6" class="text-right" v-text="cart.data.status ==='CANCELLED' && $options.filters.currencyWithoutCountry(refundedAmount) || $options.filters.currencyWithoutCountry(cart.data.balance)"></v-col>
				</v-row>
			</v-card-text>
		</div>

	</v-card>
</template>

<script>
import { CartModel, TransactionModel, EComService, EventBus, PaymentModel, PaymentService } from '@connectngo/sdk';
import RemoveButton from '@/components/Inputs/RemoveButton.vue';
import globalVariables from '@/global'

export default {
	name: 'SummaryOrderCard',

	components: {
		RemoveButton,
	},

	props: {
		cart: {
			type: [CartModel,TransactionModel],
			default: () => new CartModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		load: {
			type: Function,
			default: () => {}
		},
		showContinueButton: {
			type: Boolean,
			default: false
		},
		showDepositSection: {
			type: Boolean,
			default: false
		},
		showCartPayments: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		loading: false,
		itemsWithModifierGrouped : []
	}),
	mounted () {
		this.itemsWithModifierGrouped = this.formatItemsWithModifierGrouped();
	},
	watch : {
		cart() {
			this.itemsWithModifierGrouped = this.formatItemsWithModifierGrouped();
		}
	},
	methods: {
		handleRebateInput( rebate) {
			this.loading = true;
			new EComService().removeRebate(rebate.id)
				.then(cart => {
					EventBus.publish('CART_UPDATED', cart);
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));

		},
		getPaymentMethodName(paymentMethod) {
			let name = paymentMethod.name;
			if (paymentMethod.uid) {
				name += ' ' + paymentMethod.uid;
			}

			return name
		},
		removePayment(paymentID) {
			this.loading = true;

			new PaymentService().voidCashlessPayment(paymentID)
				.then(() => {
					EventBus.publish('CART_UPDATED', this.cart);
					this.$snack(this.$i18n.t('cart.gift.refunded'));
				})
				.catch((error) => {
					this.error = true;
					this.$handleError(this, error);
				})
				.finally(() => {
					this.loading = false;
					this.paying = false;

					this.$emit('partialPaymentCompleted');
				});
		},
		formatItemsWithModifierGrouped() {
			if(!this.modifierFfEnabled) {
				return this.cart.data.items;
			}

			let formattedData = new Map();
			// Prevent changes on original cart.data.items
			const items = this.$deepClone(this.cart.data.items)

			items.forEach(item => {
				const key = `${item.data.product.data.id}-${item.data.unitPrice}`;

				if(!formattedData.has(key)) {
					formattedData.set(key,  {
						data : {
							quantity : 0,
							subTotal : 0,
							total : 0,
							modifiers : [],
							product : item.data.product,
							unitPrice : item.data.unitPrice
						}
					})
				}

				const currentData = formattedData.get(key);
				const itemModifiers = item.data.modifiers;

				// --- Parent data
				currentData.data.quantity += item.data.quantity;
				currentData.data.subTotal += item.data.subTotal;
				currentData.data.total += item.data.total;

				if (currentData.data.modifiers.length === 0) {
					currentData.data.modifiers = itemModifiers;
				} else {
					itemModifiers.forEach(modifier => {
						const currentIndex = currentData.data.modifiers.findIndex(currentModifier => currentModifier.data.product.id === modifier.data.product.id && currentModifier.data.unitPrice === modifier.data.unitPrice)
						if (currentIndex >= 0) {
							currentData.data.modifiers[currentIndex].data.quantity += modifier.data.quantity
							currentData.data.modifiers[currentIndex].data.subTotal += modifier.data.subTotal
							currentData.data.modifiers[currentIndex].data.total += modifier.data.total
						} else {
							currentData.data.modifiers.push(modifier)
						}
					})
				}

				currentData.data.subTotalFormatted = this.$options.filters.currencyWithoutCountry(currentData.data.subTotal)
				currentData.data.totalFormatted = this.$options.filters.currencyWithoutCountry(currentData.data.total)

				formattedData.set(key, { ...currentData})
			})

			return Array.from(formattedData.values())
		}
	},
	computed: {
		totalTax() {
			return this.cart.data.taxes.reduce( function(totalTax, tax){
					return totalTax + tax.data.total;
				}, 0);
		},

		reservationDate() {
			return `&nbsp&nbsp(${this.$options.filters.tsToFormat(this.cart.data.reservation_date, this.$t('date.format.ymd'))})`;
		},

		refundedAmount() {
			const refundedPayments =  this.payments.data.payments.filter(paymentsData => paymentsData.data.type === 'DEPOSIT_REFUND')?.map(payments => payments.data.amount);
			if(refundedPayments.length > 0) {
				const result = refundedPayments.reduce((a, b) => {
					return a + b;
				});
				return -result;
			}

			return 0;
		},
		modifierFfEnabled() {
			return globalVariables.websiteConfig.featureFlagEnabled("co-8590-remove-modifier-multiplicator")
		}
	},
}
</script>

<style lang="scss" scoped>
.top-border {
	border-top: 1px solid #E0E0E0;
}

.bottom-border {
	border-bottom: 1px solid #E0E0E0;
}
.blackish-text {
	color : $blackish !important;
}
.grayish-text {
	color : $greyish !important;
}
</style>
