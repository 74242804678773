<template>
	<div>
		<div v-if="modifier.required" class="align-center">
			<template v-if="isMobile">
				<div class="row pr-4 pl-4">
					<span class="text-blackish text-bold">{{ modifier.name }}*</span>
					<span class="opacity-50 ml-2">({{$t('suffix.required')}})
						<span v-if="hasFixQty">
							x{{ modifier.min_quantity }}
						</span>
					</span>
					<span v-if="hasFixQty" class="ml-auto text-blackish text-bold">{{$options.filters.currencyWithoutCountry(displayPrice)}}</span>
				</div>
				<div class="row pa-3" v-if="!hasFixQty">
					<v-col cols="8">
						<QuantityPicker
							:present-quantity="currentQuantity"
							:min-quantity="modifier.min_quantity == 0 ? 1 : modifier.min_quantity"
							:max-quantity="modifier.max_quantity"
							:incrementValue="modifier.increment"
							:isModifier="true"
							:isRequiredModifier="true"
							:mainProductQuantity="mainProductQuantity"
							align="right"
							class="quantity-picker"
							@quantityChanged="handleQuantityChanged"/>
					</v-col>
					<v-col cols="4" align="end">
						<div class="text-blackish text-bold">{{
								$options.filters.currencyWithoutCountry(displayPrice)
							}}
						</div>
					</v-col>
				</div>
			</template>
			<v-row v-else>
				<v-col cols="6">
					<span class="text-blackish text-bold">{{ modifier.name }}*</span>
						<span class="opacity-50 ml-2">({{$t('suffix.required')}})
						<span v-if="hasFixQty">
							x{{ modifier.min_quantity }}
						</span>
					</span>
				</v-col>
				<v-col cols="4">
					<QuantityPicker
						v-if="!hasFixQty"
						:present-quantity="currentQuantity"
						:min-quantity="modifier.min_quantity == 0 ? 1 : modifier.min_quantity"
						:max-quantity="modifier.max_quantity"
						:incrementValue="modifier.increment"
						:isModifier="true"
						:isRequiredModifier="true"
						:mainProductQuantity="mainProductQuantity"
						align="right"
						class="quantity-picker"
						@quantityChanged="handleQuantityChanged"/>
				</v-col>
				<v-col cols="2" align="end">
					<div class="text-blackish text-bold">{{
							$options.filters.currencyWithoutCountry(displayPrice)
						}}
					</div>
				</v-col>
			</v-row>
		</div>
		<v-card v-else
				:class="[
					'modifier-card rounded-lg d-flex align-center mt-4',
					(modifier.min_quantity || modifier.max_quantity) ? 'pa-7' : 'pa-8'
				]"
				flat>
				<v-row>
				<v-col :cols="isMobile ? '12' :'6'" :class="[isMobile ? 'pa-0 mb-3' : '']">
					<div class="text-blackish text-bold">{{ modifier.name }}</div>
				</v-col>
				<v-col :cols="isMobile ? '8' :'4'" :class="[isMobile ? 'pl-2' : '']">
					<QuantityPicker
						:present-quantity="currentQuantity"
						:min-quantity="modifier.min_quantity"
						:max-quantity="modifier.max_quantity"
						:incrementValue="modifier.increment"
						:isRequiredModifier="false"
						:mainProductQuantity="mainProductQuantity"
						:isModifier="true"
						align="right"
						class="quantity-picker"
						@quantityChanged="handleQuantityChanged"/>
				</v-col>
				<v-col :cols="isMobile ? '4' :'2'" align="end">
					<div :class="['text-blackish text-bold', !isMobile ? 'modifier-price' : '']">
						{{ $options.filters.currencyWithoutCountry(modifier.price_lowest_available.lowest_available) }}
					</div>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import QuantityPicker from '@/components/Shared/QuantityPicker'
import { WindowWidthMixin } from '@/mixins/ProductMixin'

export default {
	name: 'ProductModifiers',
	mixins: [WindowWidthMixin],
	components: {
		QuantityPicker
	},
	props: {
		modifier: {
			type: Object,
			required: true,
		},
		requiredModifiers: {
			type: Boolean,
			default: false
		},
		product: {
			type: Object,
			required: true,
		},
		productsSelected: {
			type: Map,
			required: true
		},
	},
	computed: {
		displayPrice() {
			if (this.modifier.price_show_lowest) {
				return this.modifier.price_lowest_available.lowest_available;
			}

			return this.modifier.price
		},
		padding () {
			if (this.modifier.min_quantity || this.modifier.max_quantity) {
				return 'pa-5'
			}
			return 'pa-6'
		},
		currentQuantity () {
			const productSelected = this.mainProduct
			if (productSelected && productSelected.modifiers) {
				const modifierSelected = productSelected.modifiers.get(this.modifier.id)

				return modifierSelected ? modifierSelected.quantity : 0
			}
		},
		hasFixQty () {
			return this.modifier.min_quantity == this.modifier.max_quantity
		},
		mainProduct () {
			const mainProduct = this.productsSelected.get(this.product.data.id)

			return mainProduct ? mainProduct : null
		},
		mainProductQuantity () {
			return this.mainProduct ? this.mainProduct.quantity : 0
		}
	},
	methods: {
		handleQuantityChanged (newQuantity) {
			this.$emit('quantityModifierChanged', this.modifier, newQuantity)
		},
	},
}
</script>

<style lang="scss" scoped>
.modifier-card {
	width: 100%;
	border: 1px solid $light-grey;
}
.text-blackish {
	color: $blackish;
}
.text-bold {
	font-weight: 600;
}
.modifier-price {
	font-size: 1.05rem;
}

</style>
