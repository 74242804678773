<template>
	<div>
		<div :class="['d-flex align-center', isMobile ? 'justify-space-between' : 'justify-end', isMobile && !isModifier ? 'pr-3 pl-3' : '']">
			<v-btn icon @click="decrement" class="circular-button mx-1" :disabled="!canDecrement">
				<v-icon>mdi-minus</v-icon>
			</v-btn>
			<span class="counter-value"
				:class="isModifier ? 'mx-6' : 'mx-12'">{{ currentQuantity }}</span>
			<v-btn icon @click="increment" class="circular-button mx-1" :disabled="!canIncrement">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</div>
		<div v-if="minQuantity || maxQuantity" class="text-right">
			<span class="text-caption text-grey-dark opacity-50 ml-auto" :class="{'mr-3': !isModifier}">
				<span v-if="minQuantity && maxQuantity">{{$t('product.quantityBoth', {min : minQuantity, max : maxQuantity})}}&nbsp;</span>
				<span v-else-if="minQuantity">{{$t('product.quantityMin', {min : minQuantity})}}</span>
				<span v-else-if="maxQuantity">{{$t('product.quantityMax', {max : maxQuantity})}}</span>
			</span>
		</div>
	</div>
</template>

<script>
	import { WindowWidthMixin } from '@/mixins/ProductMixin';
	export default {
		name: 'QuantityPicker',
		mixins : [WindowWidthMixin],
		props: {
			presentQuantity: {
				type: Number,
				default: 0
			},
			minQuantity: {
				type: Number | null,
				default: null
			},
			maxQuantity: {
				type: Number | null,
				default: null
			},
			incrementValue: {
				type: Number | null,
				default: 1
			},
			isModifier: {
				type: Boolean,
				default: false
			},
			isRequiredModifier: {
				type: Boolean,
				default: false
			},
			mainProductQuantity: {
				type: Number | null,
				required: false,
				default: 0
			},
			align: {
				type: String,
				default: 'left'
			},
		},
		data () {
			return {
				currentQuantity: this.presentQuantity,
			}
		},
		created() {
			this.$root.$on('addToCartSuccess', this.handleAddToCartSuccess);
		},
		beforeDestroy() {
			this.$root.$off('addToCartSuccess', this.handleAddToCartSuccess);
		},
		computed: {
			isModifierWithoutProduct() {
				return this.mainProductQuantity == 0 && this.isModifier;
			},
			modifierQtyLowerProductQty() {
				return this.isRequiredModifier && (this.currentQuantity - this.valueStep) < (this.minQuantity * this.mainProductQuantity);
			},
			canIncrement() {

				if (this.isModifierWithoutProduct) {
					return false;
				}

				return !this.maxQuantity || this.currentQuantity < this.maxQuantity;
			},
			canDecrement() {
				if (this.modifierQtyLowerProductQty) {
					return false;
				}

				return this.currentQuantity > 0;
			},
			valueStep() {
				return this.incrementValue !== null ? this.incrementValue : 1;
			}
		},
		watch: {
			mainProductQuantity () {
				this.setModifierQuantity();
			},
			presentQuantity(newVal, oldVal) {
				this.currentQuantity = newVal

				this.$emit("quantityChanged", this.currentQuantity);
			}
		},
		methods: {
			setModifierQuantity() {
				if (this.mainProductQuantity == 0) {
					this.currentQuantity = 0;
				}

				if (this.mainProductQuantity > 0 && this.isRequiredModifier ) {
					this.currentQuantity = this.minQuantity ? this.minQuantity * this.mainProductQuantity : this.mainProductQuantity;
				}

				this.$emit("quantityChanged", this.currentQuantity);
			},
			increment() {
				if (this.canIncrement) {
					if ((this.minQuantity > 0) && this.currentQuantity == 0) {
						this.currentQuantity = this.minQuantity;
					} else {
						this.currentQuantity += this.valueStep;
					}
					this.$emit("quantityChanged", this.currentQuantity);
				}
			},
			decrement() {
				if (this.canDecrement) {
					if ((this.minQuantity > 0) && this.currentQuantity == this.minQuantity) {
						this.currentQuantity = 0;
					} else {
						this.currentQuantity -= this.valueStep;
					}
					this.$emit("quantityChanged", this.currentQuantity);
				}
			},
			handleAddToCartSuccess() {
				this.currentQuantity = 0;
			}
		}
	};
</script>

<style scoped lang="scss">
	.counter-value {
		font-weight: 600;
		font-size: 1.2rem;
	}
	.circular-button {
		border: 1px solid $grey-light;
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
</style>
