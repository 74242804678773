export function formatPriceCalendar(value, locale = 'en-US', currencyCode = 'USD'): string {
	let currencySymbol = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currencyCode
	}).formatToParts(0).find(part => part.type === 'currency')?.value.replace(/[A-Z]{2,}/g, '');

	if (!currencySymbol) {
		currencySymbol = '';
	}

	if (currencyCode === 'CAD') {
		currencySymbol = '$';
	}

	let formattedPrice: string;

	if (value >= 10000) {
		formattedPrice = `${Math.floor(value / 1000)}k`;
	} else {
		formattedPrice = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currencyCode,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(value);

		if (currencyCode === 'CAD') {
			formattedPrice = formattedPrice.replace('CA$', '$');
		}
	}

	if (value >= 10000) {
		return ['$', '£'].includes(currencySymbol)
			? `${currencySymbol}${formattedPrice}`
			: `${formattedPrice} ${currencySymbol}`;
	}

	return formattedPrice;
}
