<template>
	<v-card class="rounded-lg card-sub-card" flat>
		<div class="card-title mb-4">
			<div class="circle" v-if="number">{{ number }}</div>
			<h3>{{ title }}</h3>
		</div>
		<slot />
	</v-card>
</template>

<script>
	export default {
		name: "ProductPackageSection",
		props: {
			number: {
				type: Number,
			},
			title: {
				type: String,
				required: true,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card-title {
		display: flex;
		align-items: center;
		color: $blackish;
	}
	.card-title h3 {
		font-size: 1.4rem;
		font-weight: 500;
	}
	.circle {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		border: 1px solid $grey-dark;
		color: $blackish;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		margin-right: 1rem;
		flex-shrink: 0;
	}
	@media (max-width: 768px) {
		.card-title {
			padding-top: 0px !important;
			margin-top: 0px !important;
		}
		.card-title h3 {
			font-size: 1.1rem;
		}
	}
</style>
